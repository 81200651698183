import { Router } from '@angular/router';
import { BehaviorSubject, Observable } from "rxjs";

import { HttpHeaders, HttpClient } from '@angular/common/http';
import { JwtService } from './jwt.service';
import { LoginResponseModel } from '../model/login-response.model';
import { UserStateModel } from '../model/user-state.model';
import { AtomUserModel } from '../model/atom-user.model';
import { environment } from '../../environments/environment';
import { GroupService } from './group.service';
import { map, tap } from 'rxjs/operators';

import { Injectable } from '@angular/core';
import { TaskService } from './task.service';
import { ThemesService } from './theme.service';
import { DisplayService } from './display.service';

/**
 * import jwtService here - do not do this vice versa!! or will have circular dependency and app crash
 */
@Injectable()
export class UserService {

    private userDataSource: BehaviorSubject<UserStateModel>;
    userData$: Observable<UserStateModel>;

    private dataStore: UserStateModel = new UserStateModel();

    private rootPath: string = `${environment.apiUrl}`;

    constructor(
        private httpClient: HttpClient,
        private groupService: GroupService,
        private themeService: ThemesService,
        private taskService: TaskService,
        private displayService: DisplayService,
        private jwtService: JwtService,
        private router: Router) {
        /**
         * 20190930 set to user from token
         * on F5 / refresh, we MUST get user from store or it will be null
         * service is always rest on F5
         */
        this.dataStore = {
            user: this.jwtService.getUser(),
            isAuthenticated: this.jwtService.isLoggedIn()
        };
        this.userDataSource = <BehaviorSubject<UserStateModel>>new BehaviorSubject(this.dataStore);
        this.userData$ = this.userDataSource.asObservable();
    }

    getDataStore(): any {
        return this.userDataSource.getValue();
    }

    searchUsers(term: string): Observable<Array<AtomUserModel>> {
        let groupId = this.groupService.getSelectedGroup().groupId;
        let url: string = `${this.rootPath}/user/elastic?search=${term}&groupid=${groupId}`;
        return this.httpClient.get(url)
            .pipe(
                map(data => <Array<AtomUserModel>>data)
            );
    }

    private clearUserData() {
        console.log('clearUserData');
        this.dataStore.isAuthenticated = false;
        this.dataStore.user = new AtomUserModel();
        this.userDataSource.next(Object.assign({}, this.dataStore));
    }

    logout(): void {
        //  clear JWT
        this.jwtService.logout();
        this.clearUserData();
        // clear all cache, so next login user will not see prevouse cached data
        this.groupService.clearSelectGroup();
        this.themeService.clearAll();
        this.taskService.clearTasks();
        this.taskService.clearSelectedTask();
        this.displayService.showGroupUI(false);
        this.router.navigate(['/login']);

    }

    authenticateGoogle(socId: string, accessToken: string) {

        console.log('authenticateGoogle   ', socId);
        let url: string = `${this.rootPath}/oauth/token`;
        let formData = 'username=google:' + socId + ":" + accessToken + '&password=' + 'xxx' + '&grant_type=password';
        let headers: HttpHeaders = new HttpHeaders()//
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', 'Basic ' + btoa(environment.user + ':' + environment.secret));

        return this.httpClient.post(url, formData, { headers: headers })

            .subscribe((response: LoginResponseModel) => {
                console.log('success login response from server ...', response);
                this.jwtService.setSession(response);
                this.dataStore.user = response.user;
                this.dataStore.isAuthenticated = true;
                this.userDataSource.next(Object.assign({}, this.dataStore));
                // 
                this.groupService.getAllGroups();
                //
                this.router.navigate(['group/all']);
            },
                error => {
                    console.log('-------  LOGIN ERROR -----------', error);

                });

    }

    authenticateTelegram(atomUser: AtomUserModel) {

        console.log('authenticateTelegram   ', atomUser);

        // create JWT for this user
        let url = `${this.rootPath}/oauth/token`;
        let formData = 'username=telegram:' + atomUser.socialId + '&password=' + 'xxx' + '&grant_type=password';
        let headers: HttpHeaders = new HttpHeaders()//
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', 'Basic ' + btoa(environment.user + ':' + environment.secret));

        return this.httpClient.post(url, formData, { headers: headers })

            .subscribe((response: LoginResponseModel) => {

                this.jwtService.setSession(response);

                this.dataStore.user = response.user;
                this.dataStore.isAuthenticated = true;
                this.userDataSource.next(Object.assign({}, this.dataStore));
                // 
                this.groupService.getAllGroups();
                //
                this.router.navigate(['group/all']);
            },
                error => {
                    console.log('-------  LOGIN ERROR -----------', error);

                });

    }

    authenticatePublicAccess(): Observable<any> {

        console.log('authenticatePublicAccess   ');

        let url = `${this.rootPath}/oauth/token`;
        let formData = 'username=publicAccess:' + 'guest' + '&password=' + 'xxx' + '&grant_type=password';
        let headers: HttpHeaders = new HttpHeaders()//
            .set('Content-Type', 'application/x-www-form-urlencoded')
            .set('Authorization', 'Basic ' + btoa(environment.user + ':' + environment.secret));

        return this.httpClient.post(url, formData, { headers: headers })

            .pipe(
                tap((response: LoginResponseModel) => {
                    this.jwtService.setSession(response);

                    this.dataStore.user = response.user;
                    this.dataStore.isAuthenticated = false;
                    this.userDataSource.next(Object.assign({}, this.dataStore));
                })
            )
    }



}