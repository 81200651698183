import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { GroupService } from "../services/group.service";

import { GroupModel } from '../model/group.model';
import { Router } from '@angular/router';
import { DisplayService } from '../services/display.service';

@Component({
    selector: 'group-create',
    templateUrl: './group-create.component.html'
})
export class GroupCreateComponent implements OnInit {

    @ViewChild('name') private elementRef: ElementRef;

    createForm: FormGroup;

    createInProcess: boolean = false;


    constructor(
        private router: Router,
        private groupService: GroupService,
        private displayService: DisplayService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.initCreateForm()
        this.elementRef.nativeElement.focus();
    }

    create() {
        this.createInProcess = true;
        let createModel: GroupModel = this.getFormValueCreate();
        this.groupService.create(createModel)
            .subscribe((data: GroupModel) => {
                this.createInProcess = true;
                // refresh list of groups
                this.groupService.getAllGroups();
                // display new group
                this.groupService.showGroup(data);
                // show user list for current group
                this.displayService.showGroupUI(true);
                this.router.navigate(['group/' + data.groupId]);
            });
    }

    closeUI() {
        console.log('closeUI  ');
        this.createForm.reset();
        let grId = this.groupService.getSelectedGroup().groupId;
        if (grId >= 0) {
            // show user list for current group
            this.displayService.showGroupUI(true);
            this.router.navigate(['group/' + grId]);
        }
        else {
            console.log('navigate /group/all');
            this.router.navigate(['group/all']);
        }
    }

    private initCreateForm() {
        this.createForm = this.fb.group({
            name: ['', Validators.required]
        });
    }

    private getFormValueCreate(): GroupModel {
        let model: GroupModel = <GroupModel>{
            name: this.createForm.controls['name'].value
        };
        return model;
    }

}