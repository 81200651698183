import { Component, OnInit, OnDestroy, ViewEncapsulation } from "@angular/core";
import { Message, MessageService } from "primeng/api";
import { Subscription } from "rxjs";
import { NotifyService } from "./notify.service";


@Component({
    selector: 'app-notify',
    templateUrl: './notify.component.html',
    styleUrls: [ './notify.component.scss' ],
    encapsulation: ViewEncapsulation.None
})
export class NotifyComponent implements OnInit, OnDestroy {

    private sub: Subscription;
    
    constructor(private notifyService: NotifyService,
                private messageService: MessageService) {}

    ngOnInit(): void {
        this.sub = this.notifyService.message$.subscribe((msg: Message) => {
            this.showMessage(msg);
        });
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

    private showMessage(msg: Message): void {
        this.messageService.clear();
        this.messageService.add(msg);
    }
}