import { Component, OnInit, OnDestroy, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { Subscription } from 'rxjs';

import { GroupService } from "../services/group.service";
import { ThemesService } from '../services/theme.service';

import { GroupModel } from '../model/group.model';
import { ThemeModel } from '../model/theme.model';
import { DisplayService } from '../services/display.service';
import { DisplayUiModel } from '../model/display.ui.model';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { PlutaRouterService } from '../services/pluta-router.service';

@Component({
    selector: 'themes-preview',
    templateUrl: './themes-preview.component.html',
    styleUrls: ['./themes-preview.component.scss']
})
export class ThemesPreviewComponent implements OnInit, OnDestroy, AfterViewInit {
    // group 
    private group: GroupModel;
    private subSelectedGroup: Subscription = new Subscription();
    // group action
    private subGroupChanged: Subscription = new Subscription();

    // themes
    private themes: Array<ThemeModel> = new Array();
    private subThemes: Subscription = new Subscription();
    // drag and drop
    private draggedTheme: ThemeModel;
    private dragStarted: boolean = false;

    // displayUI - theme accordion
    private activeIndex: number = -1;

    private subsDisplayThemes: Subscription = new Subscription();

    private hidden: boolean = true;
    private subSelectedTheme: Subscription;

    constructor(
        private cdr: ChangeDetectorRef,
        private groupService: GroupService,
        private themesService: ThemesService,
        private displayService: DisplayService,
        private plutaRoute: PlutaRouterService,
        private route: ActivatedRoute,
        private router: Router
    ) { }

    ngOnInit() {

        this.subSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            this.group = data.group;
        });

        this.subsDisplayThemes = this.displayService.groupThemesPreview$
            .subscribe((model: DisplayUiModel) => {
                this.hidden = (model.openState === model.CLOSE);
            });


        this.subGroupChanged = this.displayService.groupChanged$.subscribe(data => {
            console.log('themesPreview: group action subscription');
            this.themes = this.themesService.getThemes();
        });

        this.subSelectedTheme = this.themesService.selectedTheme$.subscribe(data => {
            console.log('incoming new theme from subscription; ', data);
            this.findIndexFromThemeGID(data.themeId);
        })

    }

    ngAfterViewInit() {
        this.subThemes = this.themesService.themes$.subscribe((themes: Array<ThemeModel>) => {
            console.log('accordion init, themes arrive');
            this.themes = themes;
            this.cdr.detectChanges();
        });

    }
    /**
     * find what activeIndex for accordion shoud be set, based on ThemeId
     */
    private findIndexFromThemeGID(data: number) {
        this.activeIndex = -1;
        let counter = 0;
        this.themes.forEach(element => {
            if (element.themeId === data) {
                this.activeIndex = counter;
                return;
            }
            counter++;
        });
    }

    ngOnDestroy() {
        this.subSelectedGroup.unsubscribe();
        this.subThemes.unsubscribe();
        this.subGroupChanged.unsubscribe();
        this.subSelectedTheme.unsubscribe();
        this.subsDisplayThemes.unsubscribe();
    }


    /**
    * Panel emulation of accordion
    */
    onPanelOpen(event, theme) {
        console.log('ON PANEL OPEN------------------------', theme);
        /** check if this theme is alredy selected -> do nothing in this case */
        if (this.themesService.getSelectedTheme().themeId === theme.themeId) {
            // theme is alredy active, do nothing
            console.log('theme is alredy active');
            return;
        }
        // set data
        this.themesService.setSelectedTheme(theme);
        // show UI
        this.displayService.themeChanged(theme.themeId);

        if (this.plutaRoute.isUrlPublic()) {
            // public group url 
            console.log('public url  ', this.router.url);
            this.router.navigate(
                [this.group.publicUrl, theme.themeId],
                { relativeTo: this.route }
            );
        }
        else {
            // user is logged in, regulat URL path, navigate to Theme page
            console.log('public url  ', this.router.url);
            this.router.navigate(
                [this.group.groupId, theme.themeId],
                { relativeTo: this.route }
            );
        }

    }


    /**
     * Accordion
     */

    onTabOpen(event) {
        console.log('ON TAB OPEN------------------------', this.themes[event.index].themeId);
        /** check if this theme is alredy selected -> do nothing in this case */
        if (this.themesService.getSelectedTheme().themeId === this.themes[event.index].themeId) {
            // theme is alredy active, do nothing
            console.log('theme is alredy active');
            return;
        }
        // set data
        this.themesService.setSelectedTheme(this.themes[event.index]);
        // show UI
        this.displayService.themeChanged(this.themes[event.index].themeId);
        // navigate to Theme page
        this.router.navigate(['group/' + this.group.groupId + '/' + this.themes[event.index].themeId]);
    }

    onTabClose(event) {
        // console.log('ON TAB close------------------------');
        this.activeIndex = -1;
        // navigate to Group page 
        // this.router.navigate(['group/' + this.group.groupId]);
    }

    /**
     * 
     * Drag&Drop
     * 
     * 
     */

    /**
     * 
     * @param dropped check if dragable theme is Uper then droppable theme for ONE step.
     * In this case we do not show UI for dropping area - since nothing will happend - we only move one step up bu definition
     */
    private checkOrder(dropped: ThemeModel) {
        let dragIndex = this.themes.indexOf(this.draggedTheme);
        let dropIndex = this.themes.indexOf(dropped);
        console.log(dragIndex, dropIndex);
        if (dragIndex - dropIndex === -1) {
            // dragged is one step upper then dropped. Do nothing
            console.log('Dragged is one step in forn of dropped object. Nothing is changed...');
            dropped.dropEffectNotAllowed = false;
            return true;
        }
        return false;
    }

    dragStart(event, theme: ThemeModel) {
        console.log('drag start')
        this.dragStarted = true;
        this.draggedTheme = theme;
    }

    drop(event, theme: ThemeModel) {
        console.log('switching...   ', this.draggedTheme.themeId, theme.themeId);
        if (this.draggedTheme) {
            if (this.draggedTheme.themeId !== theme.themeId) {

                // the are different, check now if they are next to each othe
                if (this.checkOrder(theme))
                    return;

                this.themesService.switchThemes(this.group.groupId, this.draggedTheme.themeId, theme.themeId);

            }
            else {
                console.log('same Theme - nothing to switch');
            }
        }
        this.draggedTheme.dropEffect = false;
        this.draggedTheme.dropEffectNotAllowed = false;
        theme.dropEffect = false;
        theme.dropEffectNotAllowed = false;
    }

    dragEnd(event) {
        console.log('drag stop')
        this.draggedTheme = null;
        this.dragStarted = false;
    }

    dragEnter(event, theme: ThemeModel) {
        console.log('dragEnter');
        if (this.draggedTheme && this.draggedTheme) {
            if (this.draggedTheme.themeId != theme.themeId) {
                console.log('different theme checking order');

                // they are different, check now if they are next to each othe
                if (this.checkOrder(theme)) {
                    theme.dropEffectNotAllowed = true;
                    return;
                }

                theme.dropEffect = true;
                theme.dropEffectNotAllowed = false;
                console.log('setovao na ', theme.dropEffect, theme.dropEffectNotAllowed);
            }
            else {
                console.log('same Theme - not enable for switching');
                theme.dropEffectNotAllowed = true;
            }
        } else console.log('themes are not define');
    }

    dragLeave(event, theme: ThemeModel) {
        console.log('dragLeave');
        theme.dropEffect = false;
        theme.dropEffectNotAllowed = false;
    }

}