import { AuthorityModel } from "./authority.model";

export class AtomUserModel {
    id: number = -1;
    provider: string;
    userName: string;
    userPhone: string;
    socialId: string;
    socialEmail: string;
    socialImgUrl: string;
    authorities: Array<AuthorityModel>;

}