import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PublicGroupRoutingModule } from './public-group-routing.module';
import { PublicGroupComponent } from './public-group.component';
import { GroupsModule } from '../groups/groups.module';
import { ScrollPanelModule } from 'primeng/primeng';
import { ActivatedRoute, Router } from '@angular/router';


@NgModule({
  declarations: [
    PublicGroupComponent
  ],

  imports: [
    CommonModule,
    GroupsModule,

    ScrollPanelModule,
    PublicGroupRoutingModule
  ]
})
export class PublicGroupModule {

  constructor(
  ) {
  }
}
