import { Component, Input, OnInit, OnDestroy, EventEmitter, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subscription } from 'rxjs';

import { GroupService } from "../services/group.service";
import { ThemesService } from '../services/theme.service';

import { GroupModel } from '../model/group.model';
import { ThemeModel } from '../model/theme.model';
import { ConfirmationService, FileUpload } from 'primeng/primeng';
import { JwtService } from '../services/jwt.service';
import { environment } from '../../environments/environment';
import { NotifyService } from '../notify/notify.service';
import { QuillService } from '../services/quill.service';
import { DisplayService } from '../services/display.service';

/**
 * Quill service has local caracter in this component: it is cretaed and destroyed with component
 */
@Component({
    selector: 'theme-edit',
    templateUrl: './theme-edit.component.html',
    providers: [QuillService]
})
export class ThemeEditComponent implements OnInit, OnDestroy {

    _theme: ThemeModel;
    private quill: any;

    @Input('theme')
    set theme(theme: ThemeModel) {
        console.log('----------------------**********themeOnInput', theme);
        this._theme = theme;
    }
    get theme(): ThemeModel {
        return this._theme;
    }

    @Output() closeBtn: any = new EventEmitter<boolean>();

    @ViewChild('fileInput') fileInput: FileUpload;
    // url used when we attach file to comment - different from simple text comment!s
    private fileUploadURL: string;

    updateThemeForm: FormGroup;


    private subSelectedGroup: Subscription = new Subscription();
    private group: GroupModel;

    updateInProgress: boolean = false;


    constructor(private themesService: ThemesService,
        private groupService: GroupService,
        private displayService: DisplayService,
        private notifyService: NotifyService,
        private jwtService: JwtService,
        private confirmationService: ConfirmationService,
        private quillService: QuillService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {

        this.fileUploadURL = environment.apiUrl + '/fileUpload/theme/edit';

        this.updateInProgress = false;
        this.subSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            this.group = Object.assign({}, data.group);
            this.initChangeForm();
        });

    }

    ngOnDestroy() {
    }

    update() {

        this.updateInProgress = true;

        if (this.fileInput.files && this.fileInput.files.length > 0) {
            // user add file for upload, go with combine upload+comment
            console.log('file upload section');
            // close in onUpload()
            this.fileInput.upload();
        }
        else {
            // no file - just edit theme  
            console.log('no file, just text in theme');
            // fill current Theme object with updated form data
            this.processFormData();

            this.themesService.update(this.theme)
                .subscribe(data => {
                    // console.log('update recived refresh them: ', data);
                    this.displayService.themeChanged(data.themeId);
                },
                    error => {
                        console.log(error);
                        this.notifyService.showError('Greska', 'Prilikom izmene teme.');
                        this.notifyService.sendToExpLessServer('Greska prilikom izmene teme.');
                    },
                    () => {
                        // close dialog
                        this.closeDialog();
                        this.updateInProgress = false;
                    });
        }
    }

    /**
     * QUILL
     */
    editorInit(event) {
        this.quill = event.editor;
        const toolbar = this.quill.getModule('toolbar');
        toolbar.addHandler('image', () => {
            this.quillService.selectLocalImage(this.quill, 'group_' + this.group.groupId);
        });

    }

    /**
     * fill form data with current theme values
     */
    private initChangeForm() {

        this.updateThemeForm = this.fb.group({
            themeId: [this._theme.themeId],
            name: [this._theme.name, Validators.required],
            description: [this._theme.description,]
        });
    }

    deleteFile(uuidFileName: string, originalFileName: string) {
        console.log('delete file ', uuidFileName, originalFileName);
        this.confirmationService.confirm({
            message: `Da li ste sigurni da zelite da obrisete file: "${originalFileName}" ?`,
            accept: () => {
                this.updateInProgress = true;
                this.themesService.deleteFile(this._theme, uuidFileName)
                    .subscribe(
                        response => {
                            console.log('file deleted, refreshing theme');
                            response.editMode = true;
                            // update change in Themes repository
                            this.themesService.updateLocalCache(response);
                            // update current view
                            this._theme = response;
                            this.updateInProgress = false;
                        },
                        error => {
                            console.log('can not delete file from theme');
                            this.updateInProgress = false;
                        });
            },
            reject: () => {
                this.updateInProgress = false;
                // do nothing
            }
        });
    }

    /**
     * on Cancel Button click
     */
    private closeDialog() {
        console.log('closeDialog  ');
        // reset editMode - user click cancel
        this._theme.editMode = false;
        // clear any input values
        this.updateThemeForm.reset();
        this.closeBtn.emit(true);
    }

    /**
     * fill ThemModel form input form. Set description form QILL
     */
    private processFormData(): void {
        let model: ThemeModel = this.updateThemeForm.value;
        //*** 20181010, change to get raw html from quil */
        //
        // set from quill directly (Angular cut somewhere img tags..)
        //
        this.theme.description = this.quill.root.innerHTML;
        this.theme.name = model.name;
    }

    /**
     * 
     * FileUpload, action before send -> 
     *  -  add  JWT token! and 
     *  -  fill comment text part: name and desctirpion
     */
    onBeforeSend(event) {
        console.log('onBefore File Send', event);
        // fill current Theme object with updated form data
        this.processFormData();

        event.xhr.setRequestHeader("Authorization", `Bearer ${this.jwtService.getAccessToken()}`);
        event.formData.append('dataJson', JSON.stringify(this.theme));
    }

    onUpload(event) {
        console.log('onUpload', event);
        // update change in Themes repository
        let theme = JSON.parse(event.xhr.response);
        this.themesService.updateLocalCache(theme);
        this.displayService.themeChanged(theme.themeId);

        this.closeDialog();
        this.updateInProgress = true;
    }

}