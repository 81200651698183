import { Component, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from "rxjs";

import { GroupService } from "../services/group.service";
import { DisplayService } from "../services/display.service";

import { GroupModel } from "../model/group.model";
import { Router } from '@angular/router';
import { StyleModel } from '../model/style.model';
import { DisplayUiModel } from '../model/display.ui.model';

@Component({
    selector: 'group-list',
    templateUrl: './group-list.component.html',
    encapsulation: ViewEncapsulation.None
})
export class GroupListComponent implements OnInit, OnDestroy {

    pScrollHeight: StyleModel = new StyleModel();

    groups: Array<GroupModel>;

    private subsGroups: Subscription = new Subscription();

    group: GroupModel;
    private subsSelectedGroup: Subscription = new Subscription();

    private subsDisplay: Subscription = new Subscription();

    private hideUI: boolean = true;// hide by default;

    constructor(
        private groupService: GroupService,
        private router: Router,
        private displayService: DisplayService
    ) {

        this.pScrollHeight.width = '100%';
        this.pScrollHeight.height = (displayService.appHeight - 2 * 70 - 15) / 3 * 2 + 'px';
    }


    ngOnInit() {
        this.subsGroups = this.groupService.groups$.subscribe((data: Array<GroupModel>) => {
            this.groups = data;
        });

        this.subsSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            this.group = data.group;
        });

        this.subsDisplay = this.displayService.groupUsersComponent$
            .subscribe((model: DisplayUiModel) => {
                console.log('groupUsers# subsDispal', model);

                // hide if state is CLOSE
                this.hideUI = (+model.openState === +model.CLOSE);
                console.log(' model.openState === model.CLOSE', this.hideUI);

            });

    }

    ngOnDestroy() {
        this.subsGroups.unsubscribe();
        this.subsSelectedGroup.unsubscribe();
        this.subsDisplay.unsubscribe();
    }

    /** button action for crating new group */
    create() {
        // hide user list for current group
        this.displayService.showGroupUI(false);
        // navigate midle to crete view
        this.router.navigate(['group/create']);
    }

    /** Main method for selecting group and working with thems/tasks/commnets */
    selectGroup(group: GroupModel) {
        // hide old data before we start to load in background new data for new group
        this.displayService.showGroupUI(false);
        this.groupService.showGroup(group);
        // show user list for current group
        this.displayService.showGroupUI(true);
        // display group panel    
        this.router.navigate(['group/' + group.groupId]);
    }

    isActive(groupId: number) {
        if (this.group.groupId === groupId)
            return true;
        else
            return false;
    }
}