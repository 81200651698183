import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { GroupModel } from "../model/group.model";
import { InviteGroupModel } from "../model/invite-group.model";
import { ThemesService } from './theme.service';
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { AtomUserModel } from '../model/atom-user.model';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { TaskService } from './task.service';
import { CommentService } from './comment.service';

const SelectedGroupState = {
    group: {},
    selected: false
};

/**
 * 
 * Group Service -> data handling for gropus in all components.
 * Responsible only to get ALL requered Group data for App 
 * - !!!- Not responsible for UI (compinent visibliti, what data will be display...) - !!!-
 * 
 * All refreshing is done on rest - in this version we 
 * do not save object states, but always go torest to get them: eg. list of group when on egroup is updated
 * 
 * Relation in App is : Group -> Theme -> Task.
 * Here we handle Task data processing if needed, eg. when selecteing group we also read all tasks
 * 
 */
@Injectable()
export class GroupService {

    // All Groups observer 
    private groupsSource = new BehaviorSubject<Array<GroupModel>>(new Array<GroupModel>());
    groups$ = this.groupsSource.asObservable();
    // Selected Group
    private selectGroupSource = new BehaviorSubject<any>(SelectedGroupState);
    selectedGroup$ = this.selectGroupSource.asObservable();
    // Groups Users
    private groupUsersSource = new BehaviorSubject<Array<AtomUserModel>>(new Array<AtomUserModel>());
    groupUsers$ = this.groupUsersSource.asObservable();

    private selectedDataStore: {
        group: GroupModel,
        selected: boolean;
    } = {
            group: new GroupModel(),
            selected: false
        };

    private rootPath: string = `${environment.apiUrl}`;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private themesService: ThemesService,
        private taskService: TaskService,
        private commentService: CommentService
    ) { }


    /**
     * ----------------------------------------------------------------
     *                  Selected Group
     *         - !!!- Only one group can be selected in App  - !!!- 
     * ----------------------------------------------------------------
     */
    // group subscription, used for canceling previuose requests that are not finished yet, 
    // but user fast clicked to resend them
    // same object for all group calls
    private subGrp: Subscription;

    showGroupFromApi(groupId: number): void {
        console.log('selectGroupFromApi ', groupId, this.selectedDataStore.group.groupId);
        if (this.selectedDataStore.group.groupId !== +groupId) {
            console.log('selectGroupFromApi -> new group requested');
            if (this.subGrp) {
                console.log('unSubscribing...');
                this.subGrp.unsubscribe();
            }
            this.subGrp = this.getGroup(groupId)
                .subscribe(group => {
                    this.showGroup(group);
                });
        }
    }

    showGroupFromApiByHash(groupHash: string): void {
        console.log('showGroupFromApiByHash ', groupHash, this.selectedDataStore.group.groupId);
        if (this.subGrp) {
            console.log('unSubscribing...');
            this.subGrp.unsubscribe();
        }
        this.subGrp = this.getGroupByHash(groupHash)
            .subscribe(group => {
                this.showGroup(group);
            });
    }

    //
    //
    //
    showGroupAndThemeFromApi(groupId: number, themeId: number): void {
        console.log('showGroupAndThemeFromApi ', groupId, themeId, this.selectedDataStore.group);
        if (this.subGrp) {
            console.log('unSubscribing...');
            this.subGrp.unsubscribe();
        }
        this.subGrp = this.getGroup(groupId)
            .subscribe(group => {
                console.log('group from api: ', group);
                this.processGroup(group, themeId);
            });
    }

    showGroupHashAndThemeFromApi(groupHash: string, themeId: number): void {
        console.log('showGroupHashAndThemeFromApi ', groupHash, themeId, this.selectedDataStore.group);
        if (this.subGrp) {
            console.log('unSubscribing...');
            this.subGrp.unsubscribe();
        }
        this.subGrp = this.getGroupByHash(groupHash)
            .subscribe(group => {
                console.log('group from api: ', group);
                this.processGroup(group, themeId);
            });
    }
    private processGroup(group: GroupModel, themeId: number) {
        // showGroup
        this.selectedDataStore = {
            group: group,
            selected: true
        };
        this.selectGroupSource.next(this.selectedDataStore);
        // get all thems
        this.themesService.getAllThemesForGroupSelectCurrent(group.groupId, themeId);
        // get all tasks
        this.taskService.getAllTasksForGroup(group.groupId);
        // get all comments
        this.commentService.getAllCommentsForGroup(group.groupId);
    }

    //
    //
    //

    showGroupByIdAndThemeTaskFromApi(groupId: number, themeId: number, taskId: number): void {
        console.log('showGroupThemeTaskFromApi ', groupId, this.selectedDataStore.group.groupId);
        if (this.selectedDataStore.group.groupId !== +groupId) {
            console.log('selectGroupFromApi -> new group requested');
            this.getGroup(groupId)
                .subscribe(group => {
                    this.processGroupThemeTask(group, themeId, taskId);
                });
        }
    }
    showGroupByHashAndThemeTaskFromApi(group: GroupModel, themeId: number, taskId: number): void {
        console.log('showGroupByHashAndThemeTaskFromApi ', group, this.selectedDataStore.group.groupId);
        if (this.selectedDataStore.group.groupId !== +group.groupId) {
            console.log('selectGroupFromApi -> new group requested');
            this.processGroupThemeTask(group, themeId, taskId);
        }
    }

    private processGroupThemeTask(group: GroupModel, themeId: number, taskId: number) {
        // showGroup
        this.selectedDataStore = {
            group: group,
            selected: true
        };
        this.selectGroupSource.next(Object.assign({}, this.selectedDataStore));
        // get all thems
        this.themesService.getAllThemesForGroupSelectCurrent(group.groupId, themeId);
        // get all tasks
        this.taskService.getAllTasksForGroupAndSelectCurrent(group.groupId, taskId);
        // get all comments
        this.commentService.getAllCommentsForGroup(group.groupId);
    }

    //
    //
    //

    /**
     *  Read one group from api
     */
    getGroup(groupId: number): Observable<GroupModel> {
        console.log('getGroup ', groupId);
        let url: string = `${this.rootPath}/group/${groupId}`;
        return this.httpClient.get(url)
            .pipe(
                map(data => <GroupModel>data)
            );
    }
    getGroupByHash(groupHash: string): Observable<GroupModel> {
        console.log('getGroupByHash ', groupHash);
        let url: string = `${this.rootPath}/group/hash/${groupHash}`;
        return this.httpClient.get(url)
            .pipe(
                map(data => <GroupModel>data)
            );
    }

    /**
     *  Main method when selecting one group. prepare all data for group (users/thems...)
     */
    showGroup(group: GroupModel): void {

        console.log('showGroup', group);

        // clear previouse data fast, load in background while screen in clean
        this.clearSelectGroup();

        this.themesService.clearAll();
        this.taskService.clearAll();
        this.groupUsersSource.next([]);

        if (!group) {
            // redirect to home page
            this.router.navigate(['/group/all']);
            return;
        }
        if (group.groupId < 0) {
            console.log('group is not selected');
            // redirect to home page
            this.router.navigate(['/group/all']);
            return;
        }

        if (this.selectedDataStore.group.groupId !== group.groupId) {
            // init store
            this.selectedDataStore = {
                group: group,
                selected: true
            };
            this.selectGroupSource.next(Object.assign({}, this.selectedDataStore));
            // clear theme and refresh them for new group
            this.themesService.clearAll();
            // clear task and refresh them for new group
            this.taskService.clearAll();
            // read again
            this.themesService.getAllThemesForGroup(group.groupId);
            this.taskService.getAllTasksForGroup(group.groupId);
            this.commentService.getAllCommentsForGroup(group.groupId);
            this.getAllUsersForGroup(group.groupId);

        } else {
            // group dose not change, just refresh it. Eg. hire we come after groupEdit
            // emit new edited data to other subscribers
            if (group) {
                this.selectedDataStore.group = group;
                this.selectedDataStore.selected = true;
                this.selectGroupSource.next(this.selectedDataStore);
            }
        }

    }

    clearSelectGroup(): void {
        console.log('clearSelectGroup');
        this.selectedDataStore.group = new GroupModel();
        this.selectedDataStore.selected = false;
        this.selectGroupSource.next(Object.assign({}, this.selectedDataStore));
    }

    getSelectedGroup(): GroupModel {
        return this.selectGroupSource.getValue().group;
    }


    /**
     * -------------------------------------
     *          CRUD for group
     * -------------------------------------
     */

    getAllGroups(): void {
        let url: string = `${this.rootPath}/group`;
        this.httpClient.get(url)
            .pipe(
                map(data => <Array<GroupModel>>data)
            )
            .subscribe(groups => {
                this.groupsSource.next(groups);
            });
    }

    create(group: GroupModel): Observable<GroupModel> {
        let url: string = `${this.rootPath}/group`;
        return this.httpClient.post(url, group)
            .pipe(
                map(data => {
                    this.addToLocalCache(group);
                    // refresh group list in background
                    this.getAllGroups();
                    // return current change 
                    return <GroupModel>data;
                })
            );
    }

    update(group: GroupModel): Observable<GroupModel> {
        let url: string = `${this.rootPath}/group/${group.groupId}`;
        return this.httpClient.put<GroupModel>(url, group)
            .pipe(
                tap(data => {
                    // update local cache until we receive resh data from api
                    // in case of slow network
                    this.updateLocalCache(data);
                    // refresh from api - double check
                    this.getAllGroups();
                    this.showGroup(data);
                })
            );
    }

    delete(group: GroupModel): void {
        let url: string = `${this.rootPath}/group/${group.groupId}`;
        this.httpClient.delete(url)
            .subscribe(response => {
                console.log('group deleted', response);
                this.removeFromLocalCache(group);
                this.getAllGroups();
                this.themesService.clearAll();
                this.groupUsersSource.next([]);
                // clear selected group and all groups until refresh happend
                this.clearSelectGroup();
                this.groupsSource.next([]);
            });
    }

    copy(group: GroupModel, copyData: boolean, copyUsers: boolean): Observable<GroupModel> {
        let url: string = `${this.rootPath}/group/copy/${copyData}/${copyUsers}`;
        return this.httpClient.post<GroupModel>(url, group)
            .pipe(
                tap(cpGroup => {
                    this.addToLocalCache(cpGroup);
                })
            );
    }
    /**
     * Cache data operation
     * when we do CRUD, immedeatly after receiving OK from API we localy persist new state 
     * until receved fresh (same) data from api - so user have seemles CRUD operation in case of slow network  
     */
    private updateLocalCache(group: GroupModel) {
        let groups = this.groupsSource.getValue();
        //Find index of specific object using findIndex method.    
        let objIndex = groups.findIndex((obj => obj.groupId === group.groupId));
        //Update object 
        groups[objIndex] = group;
        // update subscriptions
        this.groupsSource.next(groups);
    }
    private removeFromLocalCache(group: GroupModel) {
        let groups = this.groupsSource.getValue();
        //Find index of specific object using findIndex method.    
        let objIndex = groups.findIndex((obj => obj.groupId === group.groupId));
        //Update object 
        groups.splice(objIndex, 1);
        // update subscriptions
        this.groupsSource.next(groups);
    }
    private addToLocalCache(group: GroupModel) {
        let groups = this.groupsSource.getValue();
        //Update object 
        groups.unshift(group);
        // update subscriptions
        this.groupsSource.next(groups);
    }

    /**
     * -------------------------------------
     *          Users in group
     * -------------------------------------
     */

    getAllUsersForGroup(groupId: number): void {
        console.log('getAllUsersForGroup');
        let url: string = `${this.rootPath}/user/group/${groupId}`;
        this.httpClient.get<Array<AtomUserModel>>(url)
            .pipe(
                tap(data => this.groupUsersSource.next(data))
            )
            .subscribe();
    }

    invitePeopleInGroup(invite: InviteGroupModel, group: GroupModel): Observable<Array<AtomUserModel>> {

        let url: string = '';
        let body: any = {};

        if (invite.usrs.length > 0) {
            url = `${this.rootPath}/group/invite/${invite.groupId}`;
            body.usrs = invite.usrs;
        } else {
            url = `${this.rootPath}/group/invite/email/${invite.groupId}`;
            body.email = invite.email;
        }

        return this.httpClient.put<AtomUserModel[]>(url, body)
            .pipe(
                tap(data => this.groupUsersSource.next(data))
            )

    }

    /**
     * subscribe to get event when done. Subcrition is mandatory! 
     */
    removeUserFromGroup(userId: number, group: GroupModel): Observable<any> {
        let url: string = `${this.rootPath}/group/removeuser/${group.groupId}`;
        return this.httpClient.put<AtomUserModel[]>(url, { usr: userId })
            .pipe(
                tap(data => {
                    // refresh actual group - we wont to refresh list of admins
                    this.getGroup(group.groupId)
                        .subscribe(group => {
                            this.selectedDataStore.group = group;
                            this.selectGroupSource.next(this.selectedDataStore);
                            this.groupUsersSource.next(data);
                        });

                })
            );
    }

    setAsAdminOfGroup(userId: number, group: GroupModel): Observable<GroupModel> {
        let url: string = `${this.rootPath}/group/setadmin/${group.groupId}`;
        return this.httpClient.put<GroupModel>(url, { usr: userId })
            .pipe(
                tap(data => {
                    this.selectedDataStore.group = data;
                    this.selectGroupSource.next(this.selectedDataStore);
                })
            );
    }

    removeAdminOfGroup(userId: number, group: GroupModel): Observable<GroupModel> {
        let url: string = `${this.rootPath}/group/removeadmin/${group.groupId}`;
        return this.httpClient.put<GroupModel>(url, { usr: userId })
            .pipe(
                tap(data => {
                    this.selectedDataStore.group = data;
                    this.selectGroupSource.next(this.selectedDataStore);
                })
            );
    }

    isGroupAdmin(userId4Test: number): boolean {
        try {
            if (this.getSelectedGroup().groupId >= 0)
                return this.getSelectedGroup().admins.some(userId => {
                    return userId === userId4Test
                });
        }
        catch (error) {
            console.log(error);
        }
        return false;
    }

}