import { Component, OnInit, OnDestroy } from '@angular/core';

import { GroupService } from "../services/group.service";

import { Subscription } from 'rxjs';
import { MenuItem } from 'primeng/api';
import { DisplayService } from '../services/display.service';
import { ThemesService } from '../services/theme.service';
import { TaskService } from '../services/task.service';
import { TaskModel } from '../model/task.model';

@Component({
    selector: 'group-breadcrumb',
    templateUrl: './group-breadcrumb.component.html'
})
export class GroupBreadcrumbComponent implements OnInit, OnDestroy {

    items: MenuItem[];
    home: MenuItem;

    private subsBreadcrumb: Subscription = new Subscription();
    private subsSelectedGroup: Subscription = new Subscription();
    private subSelectedTheme: Subscription = new Subscription();
    private taskSubscription: Subscription = new Subscription();

    private selection: number = -1;

    private hideBreadrumb: boolean = false;

    constructor(
        private groupService: GroupService,
        private themesService: ThemesService,
        private taskService: TaskService,
        private displayService: DisplayService,
    ) {

    }

    ngOnInit() {

        this.items = [
        ];

        this.home = { routerLink: '/group/all', icon: 'pi pi-home' };

        this.subsBreadcrumb = this.displayService.breadcrumb$.subscribe(activePath => {
            console.log('subsBreadcrumb group   ', activePath);

            this.selection = +activePath;

            if (activePath === 1) {
                console.log(' display home');
                this.setBreadCrumbHome();
            }
            if (activePath === 2) {
                console.log(' - set group with name');
                // when coming directly to link this will be filled later, use display subscription for this case
                if (this.groupService.getSelectedGroup().groupId < 0) return;
                this.setBreadCrumbGroup(this.groupService.getSelectedGroup().name);

            }
            if (activePath === 3) {
                // when coming directly to link this will be filled later, use display subscription for this case
                if (this.themesService.getSelectedTheme().themeId < 0) return;
                this.setBreadCrumbTheme(this.themesService.getSelectedTheme().name, this.groupService.getSelectedGroup().groupId);

            } if (activePath === 4) {
                // when coming directly to link this will be filled later, use display subscription for this case
                if (this.taskService.getSelectedTask().taskId < 0) return;
                this.setBreadCrumbTask(this.taskService.getSelectedTask().name, this.groupService.getSelectedGroup().groupId, this.themesService.getSelectedTheme().themeId);
            }
        });


        this.subsSelectedGroup = this.groupService.selectedGroup$.subscribe(data => {
            console.log('breadcrumb: incoming new Group from subscription; ', data);
            if (this.selection === 2)
                this.setBreadCrumbGroup(this.groupService.getSelectedGroup().name);
        });

        this.subSelectedTheme = this.themesService.selectedTheme$.subscribe(data => {
            console.log('breadcrumb: incoming new Theme from subscription; ', data);
            if (this.selection === 3)
                this.setBreadCrumbTheme(this.themesService.getSelectedTheme().name, this.groupService.getSelectedGroup().groupId);
        })

        this.taskSubscription = this.taskService.selectedTask$.subscribe((data: TaskModel) => {
            console.log('breadcrumb: incoming new Task from subscription; ', data);
            if (this.selection === 4)
                this.setBreadCrumbTask(this.taskService.getSelectedTask().name, this.groupService.getSelectedGroup().groupId, this.themesService.getSelectedTheme().themeId);
        });
    }

    ngOnDestroy(): void {
        this.subsBreadcrumb.unsubscribe();
        this.subSelectedTheme.unsubscribe();
        this.subSelectedTheme.unsubscribe();
        this.taskSubscription.unsubscribe();
    }

    private setBreadCrumbHome() {
        console.log('setBreadCrumbHome');
        this.items = [];
    }

    private setBreadCrumbGroup(groupName: string) {
        console.log('setBreadCrumbGroup', groupName);
        this.items = [
            {
                label: 'Grupa: ' + groupName,
                disabled: true,
            }
        ];
    }

    /**
     * 
     * @param themeName theme name
     * @param groupId groupId as string (we get it as string in URL)
     */
    private setBreadCrumbTheme(themeName: string, groupId: any) {
        console.log('setBreadCrumbTheme', themeName);
        this.items = [
            {
                label: 'Grupa',
                routerLink: '/group/' + groupId,
            }, {
                label: 'Tema: ' + themeName,
                disabled: true
            }
        ];
    }
    private setBreadCrumbTask(taskName: string, groupId: any, themeId: any) {
        console.log('setBreadCrumbTask', taskName, groupId, themeId);
        this.items = [
            {
                label: 'Grupa',
                routerLink: '/group/' + groupId
            }, {
                label: 'Tema',
                routerLink: '/group/' + groupId + '/' + themeId
            }, {
                label: 'Zadatak: ' + taskName,
                disabled: true
            }
        ];
    }

}