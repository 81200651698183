import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { GroupService } from "../services/group.service";
import { UserService } from "../services/user.service";

import { GroupModel } from '../model/group.model';
import { InviteGroupModel } from "../model/invite-group.model";
import { AtomUserModel } from '../model/atom-user.model';
import { Subscription } from 'rxjs';
import { DisplayService } from '../services/display.service';

@Component({
    selector: 'group-add-users',
    templateUrl: './group-add-users.component.html'
})
export class GroupAddUsersComponent implements OnInit {

    group: GroupModel;
    groupSelected: Subscription;

    @Output() updated: any = new EventEmitter<boolean>();

    userAddForm: FormGroup;
    avaliableUsers: Array<AtomUserModel>;

    constructor(
        private groupService: GroupService,
        private userService: UserService,
        private dislayService: DisplayService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.groupSelected = this.groupService.selectedGroup$.subscribe(data => {
            console.log('subSelected group   ', data);
            this.group = Object.assign({}, data.group);
            this.initUserAddForm();
        });
        this.watchModelChanges();
    }

    searchUsers(event: any) {
        this.userService.searchUsers(event.query).subscribe(data => {
            this.avaliableUsers = data;
        })
    }

    addUsers() {
        let updateModel: InviteGroupModel = this.getFormValueUpdate();
        this.groupService.invitePeopleInGroup(updateModel, this.group)
            .subscribe(data => {
                this.updated.emit(true);
                this.dislayService.groupUsersChanged();
            });
    }

    canceAddUsers() {
        console.log('canceAddUsers');
        this.updated.emit(true);
    }

    disableUsrs() {
        let usrsControl = this.userAddForm.get('usrs');
        let email = this.userAddForm.controls['email'].value;
        if (email) {
            usrsControl.markAsPristine();
            usrsControl.disable();
        } else {
            usrsControl.enable();
        }
    }

    disableEmail() {
        let emailControl = this.userAddForm.get('email');
        let usrs = this.userAddForm.controls['usrs'].value;
        if (usrs.length > 0) {
            emailControl.markAsPristine();
            emailControl.disable();
        } else {
            emailControl.enable();
        }
    }

    private initUserAddForm() {
        this.userAddForm = this.fb.group({
            groupId: [this.group.groupId, Validators.required],
            usrs: [{ value: [], disabled: false }, Validators.nullValidator],
            email: [{ value: '', disabled: false }, Validators.compose([Validators.email, Validators.nullValidator])]
        });
    }

    private getFormValueUpdate(): any {
        let usrs = this.userAddForm.controls['usrs'].value.map((item: AtomUserModel) => {
            return item.id;
        })
        let model: InviteGroupModel = <InviteGroupModel>{
            groupId: this.userAddForm.controls['groupId'].value,
            usrs: usrs,
            email: this.userAddForm.controls['email'].value
        };
        return model;
    }

    private watchModelChanges() {
        this.userAddForm.controls['usrs'].valueChanges.subscribe((usrs: Array<AtomUserModel>) => {
            this.disableEmail();
        });
    }

}