import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { GroupsRoutingModule } from './groups-routing.module';
import { GroupsComponent } from './groups.component';
import { AccordionModule, AutoCompleteModule, BreadcrumbModule, ButtonModule, ConfirmDialogModule, ScrollPanelModule, DataListModule, InputTextModule, MenuModule, PanelModule, EditorModule, FieldsetModule, CheckboxModule, DragDropModule, FileUploadModule, DataScrollerModule, TabViewModule, ConfirmationService } from 'primeng/primeng';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DataViewModule } from 'primeng/dataview';
import { ClipboardModule } from 'ngx-clipboard';
import { AngularFontAwesomeModule } from 'angular-font-awesome';
import { GroupAllComponent } from 'src/app/group-all/group-all.component';
import { GroupListComponent } from 'src/app/group-list/group-list.component';
import { GroupCreateComponent } from 'src/app/group-create/group-create.component';
import { GroupCopyComponent } from 'src/app/group-copy/group-copy.component';
import { GroupBreadcrumbComponent } from 'src/app/group-breadcrumb/group-breadcrumb.component';
import { GroupAddUsersComponent } from 'src/app/group-add-users/group-add-users.component';
import { GroupUpdateComponent } from 'src/app/group-update/group-update.component';
import { GroupUsersComponent } from 'src/app/group-users/group-users.component';
import { ThemesPreviewComponent } from 'src/app/themes-preview/themes-preview.component';
import { ThemeViewComponent } from 'src/app/theme-view/theme-view.component';
import { ThemeEditComponent } from 'src/app/theme-edit/theme-edit.component';
import { ThemeAddComponent } from 'src/app/theme-add/theme-add.component';
import { GroupComponent } from 'src/app/group/group.component';
import { ThemeComponent } from 'src/app/theme/theme.component';
import { ChatComponent } from 'src/app/chat/chat.component';
import { TaskComponent } from 'src/app/task/task.component';
import { TaskListComponent } from 'src/app/task-list/task-list.component';
import { TaskViewEditComponent } from 'src/app/task-view-edit/task-view-edit.component';
import { TaskNameListForThemeComponent } from 'src/app/task-name-list/task-name-list.component';
import { TaskAddComponent } from 'src/app/task-add/task-add.component';
import { CommentListComponent } from 'src/app/comment-list/comment-list.component';
import { CommentViewEditComponent } from 'src/app/comment-view-edit/comment-view-edit.component';
import { CommentAddComponent } from 'src/app/comment-add/comment-add.component';
import { GroupService } from 'src/app/services/group.service';
import { FileService } from 'src/app/services/file.service';
import { UploadService } from 'src/app/services/upload.service';
import { ChatService } from 'src/app/services/chat.service';
import { TaskService } from 'src/app/services/task.service';
import { CommentService } from 'src/app/services/comment.service';
import { ThemesService } from 'src/app/services/theme.service';
import { DisplayService } from 'src/app/services/display.service';

@NgModule({
  declarations: [
    GroupAllComponent,
    GroupListComponent,
    GroupCreateComponent,
    GroupCopyComponent,
    GroupBreadcrumbComponent,
    GroupAddUsersComponent,
    GroupUpdateComponent,
    GroupUsersComponent,
    ThemeAddComponent,
    ThemeEditComponent,
    ThemeViewComponent,
    ThemesPreviewComponent,
    CommentAddComponent,
    CommentViewEditComponent,
    CommentListComponent,
    TaskAddComponent,
    TaskViewEditComponent,
    TaskListComponent,
    TaskNameListForThemeComponent,
    TaskComponent,
    ChatComponent,
    ThemeComponent,
    GroupComponent,
    GroupsComponent],

  imports: [
    CommonModule,
    AccordionModule,
    AutoCompleteModule,
    BreadcrumbModule,
    ButtonModule,
    ConfirmDialogModule,
    ScrollPanelModule,
    DataListModule,
    InputTextModule,
    MenuModule,
    PanelModule,
    EditorModule,
    FieldsetModule,
    FormsModule,
    CheckboxModule,
    ReactiveFormsModule,
    DragDropModule,
    DataViewModule,
    FileUploadModule,
    DataScrollerModule,
    TabViewModule,
    ClipboardModule,
    AngularFontAwesomeModule,
    GroupsRoutingModule
  ],

  providers: [
    GroupService,
    ConfirmationService,
    DisplayService,
    ThemesService,
    CommentService,
    TaskService,
    ChatService,
    UploadService,
    FileService
  ],

  exports: [
    ThemesPreviewComponent
  ]
})
export class GroupsModule { }
