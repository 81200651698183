import { Component, OnInit, OnDestroy, Input, AfterViewInit } from '@angular/core';

import { Subscription } from 'rxjs';
import { CommentModel } from '../model/comment.model';
import { CommentService } from '../services/comment.service';

@Component({
    selector: 'comment-list',
    templateUrl: './comment-list.component.html'
})
export class CommentListComponent implements OnInit, OnDestroy {

    @Input() artifactType;

    private artifactId: number;
    @Input('artifactId')
    set themeOnInput(artifactId: number) {
        // console.log('----------------------**********commnetListOnInput, artifactId', artifactId);
        this.artifactId = artifactId;
        // read commnets for this ArtifactId 
        this.comments = this.commentService.getComments().filter(cmnt => {
            return +cmnt.artifactType === +this.artifactType &&
                +cmnt.artifactId === +this.artifactId
        });
    }

    private subSelectedComments: Subscription = new Subscription();
    private subComments: Subscription = new Subscription();
    comments: Array<CommentModel> = [];

    constructor(private commentService: CommentService) {
    }

    ngOnInit() {
        // console.log('cmnt-list ngOnInit ', this.artifactType, this.artifactId);
        this.subComments = this.commentService.comments$.subscribe(data => {
            this.comments = data.filter(cmnt => {
                return +cmnt.artifactType === +this.artifactType &&
                    +cmnt.artifactId === +this.artifactId
            });
        });

    }

    ngOnDestroy() {
        this.subSelectedComments.unsubscribe();
        this.subComments.unsubscribe();
    }

}