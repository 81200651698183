import { Component, Input, OnInit, ViewChild, OnDestroy, EventEmitter, Output, ElementRef, Renderer2 } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from "@angular/forms";

import { CommentService } from "../services/comment.service";

import { CommentModel } from '../model/comment.model';
import { environment } from '../../environments/environment';
import { JwtService } from '../services/jwt.service';
import { FileUpload } from 'primeng/primeng';

@Component({
    selector: 'comment-add',
    templateUrl: './comment-add.component.html',
    styleUrls: ['comment-add.component.scss']
})
export class CommentAddComponent implements OnInit, OnDestroy {

    @ViewChild('fileInput') fileInput: FileUpload;
    @ViewChild('cmntarea') cmntarea: ElementRef;
    // url used when we attach file to comment - different from simple text comment!s
    private fileUploadURL: string;

    // 1:Theme; 2:Task
    @Input() artifactType: number;
    @Input() artifactId: number;

    @Output() onChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    commentAddForm: FormGroup;

    createInProgress: boolean = false;

    constructor(
        private commentService: CommentService,
        private jwtService: JwtService,
        private fb: FormBuilder,
        public renderer: Renderer2
    ) {
    }

    ngOnInit() {
        this.fileUploadURL = environment.apiUrl + '/fileUpload/comment';
        this.initCommentAddForm();
    }

    ngOnDestroy(): void {
    }

    autogrow() {
        let scrollHeigh = this.cmntarea.nativeElement.scrollHeight;
        // console.log(scrollHeigh);
        if (scrollHeigh < 20) return;
        this.renderer.setStyle(this.cmntarea.nativeElement, 'overflow', 'hidden');
        this.renderer.setStyle(this.cmntarea.nativeElement, 'width', '100%');
        this.renderer.setStyle(this.cmntarea.nativeElement, 'resize', 'none');
        this.renderer.setStyle(this.cmntarea.nativeElement, 'height', '0px');
        this.renderer.setStyle(this.cmntarea.nativeElement, 'height', scrollHeigh + 'px');
    }

    add() {
        console.log('add comment ', this.artifactType, this.artifactId);
        this.createInProgress = true;
        if (this.fileInput.files && this.fileInput.files.length > 0) {
            // user add file for upload, go with combine upload+commnet
            console.log('file upload section');
            // check onBeforeSend...
            this.fileInput.upload();
        }
        else {
            // no file - just comment to add
            let model: CommentModel = this.getFormValue();
            this.commentService.create(model).subscribe(
                comment => {
                    this.commentAddForm.controls['comment'].reset();
                    // enable send button
                    this.createInProgress = false;
                    this.onChange.emit(true);
                },
                err => {
                    // enable send button
                    this.createInProgress = false;
                    console.log(err);
                });
        }
    }

    /**
     * 
     * FileUpload, action before send -> 
     *  -  add  JWT token! and 
     *  -  fill comment text part: name and desctirpion
     */
    onBeforeSend(event) {
        console.log(event);
        event.xhr.setRequestHeader("Authorization", `Bearer ${this.jwtService.getAccessToken()}`);
        event.formData.append('dataJson', JSON.stringify(this.getFormValue()));
    }

    onUpload(event) {
        console.log(event);
        // update cache with new task, while in backgorund refreshing all themes   
        this.commentService.addToLocalCache(JSON.parse(event.xhr.response));
        // handle UI
        this.commentAddForm.controls['comment'].reset();
        this.createInProgress = false;
        this.onChange.emit(true);
    }

    private initCommentAddForm() {
        this.createInProgress = false;
        this.commentAddForm = this.fb.group({
            artifactId: [this.artifactId],
            artifactType: [this.artifactType],
            comment: ['', Validators.required]
        });
    }

    private getFormValue(): CommentModel {
        let model: CommentModel = this.commentAddForm.value;
        // sometimes artifactId is lost. set it again
        model.artifactType = this.artifactType;
        model.artifactId = this.artifactId;
        return model;
    }

}