
import { Component, ViewEncapsulation, OnInit, OnDestroy, ViewChild, ElementRef, NgZone, Renderer2 } from "@angular/core"
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from "rxjs";

import { UserService } from '../../services/user.service'; 
import { AtomUserModel } from "../../model/atom-user.model";
import { FacebookLoginProvider, GoogleLoginProvider, LinkedinLoginProvider } from "../../social-providers";
import { AuthService } from "../../services/auth.service";
 
declare var ga: Function;

@Component({
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LoginComponent implements OnInit, OnDestroy {

    @ViewChild('telegramlogin') telegramLogin: ElementRef;

    loadAPI: Promise<any>;

    loginDisabled: boolean = false;
    private subUserData: Subscription = new Subscription();

    /** show body backgroun only on login page */
    private BODY_CLASS_NAME: string = 'login-page-body';
    sub: any;

    constructor(
        private socialAuthService: AuthService,
        private renderer: Renderer2,
        private zone: NgZone,
        private userService: UserService,
        private router: Router) {

        // add style class to body
        this.renderer.addClass(document.body, this.BODY_CLASS_NAME);

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });

        // https://stackoverflow.com/questions/35296704/angular2-how-to-call-component-function-from-outside-the-app
        // https://stackoverflow.com/questions/35276291/how-to-expose-angular-2-methods-publicly/35276652
        window['angularComponentReference'] = {
            component: this,
            componentFn: (value) => this.onTelegramAuth(value),
            zone: zone
        };

        console.log('reference added');

    }


    public socialSignIn(socialPlatform: string) {
        let socialPlatformProvider;
        if (socialPlatform === "facebook") {
            socialPlatformProvider = FacebookLoginProvider.PROVIDER_ID;
        } else if (socialPlatform === "google") {
            socialPlatformProvider = GoogleLoginProvider.PROVIDER_ID;
        } else if (socialPlatform === "linkedin") {
            socialPlatformProvider = LinkedinLoginProvider.PROVIDER_ID;
        }

        this.socialAuthService.signIn(socialPlatformProvider).then(
            (userData) => {
                console.log(socialPlatform + " sign in data : ", userData);
                // Now sign-in with userData
                if (socialPlatform === "facebook") {

                } else if (socialPlatform === "google") {
                    this.userService.authenticateGoogle(userData.id, userData.token);
                } else if (socialPlatform === "linkedin") {

                }

            }
        );
    }

    ngAfterViewInit() {
        this.loadAPI = new Promise((resolve) => {
            this.loadTelegramScript();
            resolve(true);
        });
    }

    public onTelegramAuth(user) {
        this.zone.run(() => {
            console.log('user from Telegram: ', user);
            let au = new AtomUserModel();
            au.socialId = user.id;
            au.socialImgUrl = user.photo_url;
            let lastName = user.last_name;

            if (!lastName)
                lastName = '';

            au.userName = user.first_name + ' ' + lastName;
            console.log(au);
            this.userService.authenticateTelegram(au);
        });
    }

    public loadTelegramScript() {

        let dynamicScripts = ["https://telegram.org/js/telegram-widget.js?4"];
        let node = document.createElement('script');
        node.src = dynamicScripts[0];
        node.type = 'text/javascript';
        node.async = true;
        node.charset = 'utf-8';
        node.setAttribute('data-telegram-login', 'PlutaBot');
        node.setAttribute('data-size', 'medium');
        node.setAttribute('data-userpic', 'true');
        node.setAttribute('data-radius', '0');
        node.setAttribute('data-onauth', 'onTelegramAuth(user)');
        node.setAttribute('data-request-access', 'write');

        // create telegram login button on UI
        // this.telegramLogin.nativeElement.appendChild(node);

    }

    ngOnInit() {
        this.subUserData = this.userService.userData$.subscribe(data => {
            if (data.isAuthenticated) {
                this.router.navigate(['group/all']);
            }
        });
    }

    ngOnDestroy() {
        this.renderer.removeClass(document.body, this.BODY_CLASS_NAME);
        this.subUserData.unsubscribe();

    }

}