import { Component, Inject, forwardRef, ViewEncapsulation, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AppComponent } from '../app.component';

import { UserService } from '../services/user.service';
import { NotificationService } from "../services/notification.service";
import { NotifyUserGroupModel } from "../model/notify-user-group.model";
import { GroupService } from '../services/group.service';
import { ChatService } from '../services/chat.service';
import { ChatModel } from '../model/chat.model';
import { ChatInvokeModel } from '../model/chat-invoke.model';
import { AtomUserModel } from '../model/atom-user.model';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Component({
    selector: 'vmk-menu-topbar',
    templateUrl: './menu-topbar.component.html',
    styleUrls: ['./menu-topbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class MenuTopbarComponent implements OnInit, OnDestroy {

    user: AtomUserModel;
    isAuthenticated: boolean = false;
    notificationsData: Array<string>;
    messagesData: Array<ChatModel>;
    chatActive: boolean = false;

    private subscription: Subscription = new Subscription();

    constructor(
        @Inject(forwardRef(() => AppComponent)) public app: AppComponent,
        private userService: UserService,
        private socialAuthService: AuthService,
        private groupService: GroupService,
        private notifyService: NotificationService,
        private chatService: ChatService,
        private router: Router
    ) {
        this.notificationsData = [];
        this.messagesData = [];
    }

    ngOnInit() {
        this.subscription = this.userService.userData$.subscribe(data => {
            console.log('menu onInit: ', data);
            this.user = data.user;
            this.isAuthenticated = data.isAuthenticated;
            if (this.isAuthenticated) {
                this.notifyService.socketConnect(this.user.id);
                this.chatService.socketConnect(this.user.id);
                this.notifyService.getNotifications().subscribe(
                    (data: NotifyUserGroupModel) => {
                        if (data.newUserId.some(userId => userId === this.userService.getDataStore().user.id)) {
                            this.groupService.getAllGroups();
                        }
                        this.notificationsData.push('Dodat korisnik u grupu.');
                    }
                );
                this.notifyService.getChatNotifications().subscribe(
                    (data: Array<ChatModel>) => {
                        this.messagesData = this.filterLastMessagesByUser(data);
                    }
                );
                this.chatService.isChatOpened$.subscribe((isOpened: boolean) => {
                    this.chatActive = isOpened;
                });
            }
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    openChat(message: ChatModel): void {
        let chatInvoke: ChatInvokeModel = new ChatInvokeModel();
        chatInvoke.chatId = message.chatId;
        this.chatService.invokeChat(chatInvoke);
    }

    logout() {
        this.socialAuthService.signOut();
        this.userService.logout();
    }

    login() {
        this.router.navigate(['/login']);
    }

    private filterLastMessagesByUser(data: Array<ChatModel>): Array<ChatModel> {
        let retVal: Array<ChatModel> = [];
        data.filter((message: ChatModel) => {
            let alreadyContainedAndNotMine: boolean = retVal.some((item: ChatModel) => { return message.chatId === item.chatId && message.userId !== this.userService.getDataStore().user.id; });
            if (!alreadyContainedAndNotMine) {
                retVal.push(message);
            }
        });
        return retVal;
    }

}