import * as moment from 'moment';
import { FileModel } from './fileModel.model';

export class ThemeModel {

    themeId: number = -1;// -1 for N/A
    groupId: number;
    name: string;
    description: string;
    orderIndex: number;

    likes: number;
    createdAt: moment.Moment;
    statusAt: moment.Moment;
    userId: number;
    userName: string;
    userInitials: string;
    userAvatarUrl: string;

    fileModel: FileModel;

    //UI
    dropEffect: boolean = false;
    dropEffectNotAllowed: boolean = false; // disable drop area by default
    editMode: boolean = false;
}