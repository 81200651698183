
import { ErrorHandler, Injectable } from '@angular/core';

import * as exceptionless from '../../../../node_modules/exceptionless';

/**
 * send exception automaticaly to exceptionLess server. Inserted in app.modules
 */
@Injectable()
export class CustomErrorHandler implements ErrorHandler {

  private client: exceptionless.ExceptionlessClient;

  constructor() {

    this.client = new exceptionless.ExceptionlessClient('a71uaqn0CCKloguHa1qmxEZ8td1Yh4imPZ9iwt7r');
    this.client.config.useDebugLogger();

  }

  handleError(error) {
    /** display also in console */
    console.log(error);
    try {
      // console.log('++++');
      // console.log(error);
      // console.log(error.message);
      // console.log(error.name);
      // console.log(error.stack);
      // console.log('----');
      //this one dose not work 
      this.client.submitLog(error.message + '   --   ' + error.stack);

      // try {
      //   throw new Error('this one works');
      // } catch (error2) {
      //   this.client.submitException(error2);
      // }
    }
    catch (err) {
      console.log('dd', err);

    }
  }
}