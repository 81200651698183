import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { PublicGroupComponent } from './public-group.component';
import { GroupComponent } from 'src/app/group/group.component';
import { ThemeComponent } from 'src/app/theme/theme.component';
import { TaskComponent } from 'src/app/task/task.component';

const routes: Routes = [
  {
    path: 'public', component: PublicGroupComponent,
    children: [
      { path: ':groupHash', component: GroupComponent },
      { path: ':groupHash/:themeId', component: ThemeComponent },
      { path: ':groupHash/:themeId/:taskId', component: TaskComponent }
    ]
  },

];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class PublicGroupRoutingModule { }
