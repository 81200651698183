import * as moment from 'moment';

export class CommentModel {
    public commentId: number;
    public artifactType: number;
    public artifactId: number;
    public createdAt: moment.Moment;
    public likes: number;
    public comment: string;
    public userName: string;
    public userAvatarUrl: string;
    public userId: number;

    public fileModel: string;

    constructor(artifactType: number, artifactId: number, comment: string, fileModel: string) {
        this.artifactType = artifactType;
        this.artifactId = artifactId;
        this.comment = comment;
        this.fileModel = fileModel;
    }

}