import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { GroupService } from "../services/group.service";

import { GroupModel } from '../model/group.model';
import { NotifyService } from '../notify/notify.service';

@Component({
    selector: 'group-copy',
    templateUrl: './group-copy.component.html'
})
export class GroupCopyComponent implements OnInit {

    @Input() group: GroupModel;
    @Output() updated: any = new EventEmitter<boolean>();

    copyForm: FormGroup;
    copyInProcess: boolean = false;

    constructor(
        private groupService: GroupService,
        private notifyService: NotifyService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.initCopyForm();
    }

    copy() {
        this.copyInProcess = true;
        let updateModel: GroupModel = this.getFormValueUpdate();
        // copy object - so we don't change existing values in UI
        let copiedGroup = Object.assign({}, this.group);
        // only set new group name
        copiedGroup.name = updateModel.name;
        this.notifyService.showInfo('Kopiranje', 'Kopiranje ce trajati duze ukoliko imate puno file-ova u grupi. Refreshujte stranu ukoliko ne budete videli novu grupu...');
        this.groupService.copy(
            copiedGroup,
            this.copyForm.controls['copyData'].value,
            this.copyForm.controls['copyUsers'].value)
            .subscribe(newGroup => {
                // show new copied group
                this.groupService.showGroup(newGroup);
                this.updated.emit(true);
                this.notifyService.showInfo('Kopiranje', 'Uspesno prekopirana grupa');
            });
        // .subscribe(uuidData => {
        //     console.log(uuidData);
        //     this.notifyService.showInfo('Kopiranje', 'Kopiranje grupe se nastavlja u pozadini...');
        // });
    }

    cancelCopy() {
        this.updated.emit(true);
    }

    private initCopyForm() {
        this.copyForm = this.fb.group({
            groupId: [this.group.groupId, Validators.required],
            name: [this.group.name + '_new', Validators.required],
            copyData: [true],
            copyUsers: [false]
        });
    }

    private getFormValueUpdate(): GroupModel {
        let model: GroupModel = <GroupModel>{
            groupId: this.copyForm.controls['groupId'].value,
            name: this.copyForm.controls['name'].value
        };
        return model;
    }
}