import { Routes, RouterModule } from '@angular/router';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [

    { path: '', redirectTo: 'group/all', pathMatch: 'full' },

    { path: '**', redirectTo: 'group/all' }
];

export const AppRoutes: ModuleWithProviders = RouterModule.forRoot(routes);


