
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs"; 

// import * as socketIo from 'socket.io-client';
import { ChatInvokeModel } from '../model/chat-invoke.model';
import { ChatModel } from '../model/chat.model';

@Injectable()
export class ChatService {

    private socket;
    private invokeStateSource = new BehaviorSubject<ChatInvokeModel>(null);
    private isChatOpenedSource = new BehaviorSubject<boolean>(false);

    public invokeState$ = this.invokeStateSource.asObservable();
    public isChatOpened$ = this.isChatOpenedSource.asObservable();

    constructor() { }

    socketConnect(userId: number): void {
        // this.socket = socketIo('http://socketio.subarevic.com/chat', { query: { token: "8", userid: userId } });
        // this.socket = socketIo('http://socketio.subarevic.com/chat');
    }

    socketDisconnect(): void {
        this.socket.disconnect();
    }

    invokeChat(model: ChatInvokeModel): void {
        this.socket.emit('online', model);
        this.invokeStateSource.next(model);
    }

    sendMessage(model: ChatModel): void {
        this.socket.emit('chatmsg', model);
    }

    getMessages(): Observable<Array<ChatModel>> {
        return Observable.create(observer => {
            this.socket.on('message', (data) => {
                observer.next(<Array<ChatModel>>data);
            });
        });
    }

    resetInvokeState(): void {
        this.invokeStateSource.next(new ChatInvokeModel());
    }

    chatIsOpened(opened: boolean): void {
        this.isChatOpenedSource.next(opened);
    }
}