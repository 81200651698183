
export class DisplayUiModel {

    // actions
    public OPEN: number = 1;
    public CLOSE: number = 2;
    public TOGGLE: number = 3;
    public REFRESH: number = 4;

    // types
    TYPE_GROUP: string = 'GROUP';
    TYPE_THEME: string = 'THEME';
    TYPE_TASK: string = 'TASK';
    TYPE_COMMNET: string = 'CMNT';

    // type of artifact: group/theme/task...
    public artifactType: string = '';

    // Id of selected artifact: can be group, theme, task...
    public artifactId: number;

    // 0: N/A, 1: open; 2: close
    public openState: number = 2; //default is close
}