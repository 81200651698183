import { Injectable } from '@angular/core';

import { environment } from '../../environments/environment';

import { NotifyService } from '../notify/notify.service';
import { UploadService } from './upload.service';
import { GeneralValueModel } from '../model/general-value.model';
import { HttpEventType, HttpResponse } from '@angular/common/http';

/** 
 * !!!! This service MUST be local to comoponent/directive !!!!!
 * 
 * declare it in each cmponent, not globaly - otherwise we will have mixing of requests
 * 
 * upload image to quill in separte josb and on to Pluta storage (not directly in quill as image:data)
 * 
 */
@Injectable()
export class QuillService {

    private quill: any;
    private artifactId: string;

    constructor(
        private uploadService: UploadService,
        private notifyService: NotifyService) {
    }

    selectLocalImage(quill: any, artifactId: string) {

        this.quill = quill;
        this.artifactId = artifactId;

        const input = document.createElement('input');
        input.setAttribute('type', 'file');
        input.click();

        // Listen upload local image and save to server
        input.onchange = () => {
            const file = input.files[0];

            // file type is only image.
            if (/^image\//.test(file.type)) {
                this.saveToServer(file);
            } else {
                console.warn('You could only upload images.');
                this.notifyService.showError('Greska', 'izabrani file nije slika');
            }
        };
    }


    /**
     * Step2. save to server
     *
     * @param {File} file
     */
    saveToServer(file: File) {
        console.log(file);
        // get editor position
        const range = this.quill.getSelection();
        // prepare form data for request
        const fd = new FormData();
        fd.append('image', file);
        fd.append('someIdent', this.artifactId);

        this.uploadService.uploadFile(environment.imgUploadApi, fd)
            .subscribe(
                event => {
                    // console.log(event);
                    if (event.type == HttpEventType.UploadProgress) {
                        const percentDone = Math.round(100 * event.loaded / event.total);
                        console.log(`File is ${percentDone}% loaded.`);
                        this.notifyService.showProgresMsg('', 'preneto: ' + percentDone + '%');
                    } else if (event instanceof HttpResponse) {
                        console.log('File is completely loaded!', event);
                        const url: GeneralValueModel = event.body;
                        this.insertToEditor(range, url.value);
                    }
                },
                (err) => {
                    console.log("Upload Error:", err);
                }, () => {
                    console.log("Upload done");
                }
            );

        // const xhr = new XMLHttpRequest();
        // xhr.onprogress = (event) => {
        //     this.updateProgress(event);
        // };
        // xhr.open('POST', environment.imgUploadApi, true);
        // xhr.setRequestHeader("Authorization", `Bearer ${this.jwtService.getAccessToken()}`);

        // xhr.onload = () => {
        //     if (xhr.status === 200) {
        //         // this is callback data: url 
        //         // const url = JSON.parse(xhr.responseText).data;
        //         const url = xhr.responseText;
        //         this.insertToEditor(range, url);
        //     }
        //     else {
        //         this.notifyService.sendToExpLessServer('Upload img wrong status: ' + xhr.status);
        //     }
        // };
        // xhr.send(fd);
    }

    /**
     * Step3. insert image url to rich editor.
     *
     * @param {string} url
     */
    insertToEditor(range, url: string) {
        // push image url to rich editor.
        console.log('insertToEditor');
        this.quill.insertEmbed(range.index, 'image', url);
        this.quill.insertText(range.index + 1, '\n');
    }

}