import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { GroupCreateComponent } from 'src/app/group-create/group-create.component';
import { GroupAllComponent } from 'src/app/group-all/group-all.component';
import { GroupComponent } from 'src/app/group/group.component';
import { ThemeComponent } from 'src/app/theme/theme.component';
import { TaskComponent } from 'src/app/task/task.component';
import { GroupsComponent } from './groups.component';
import { CanDeactivateGuard } from 'src/app/can-deactivate.guard';

const routes: Routes = [
  {
    path: 'group', component: GroupsComponent,
    children: [
      { path: 'create', component: GroupCreateComponent, pathMatch: 'full' },
      { path: 'all', component: GroupAllComponent, pathMatch: 'full' },
      { path: ':groupId', component: GroupComponent },
      {
        path: ':groupId/:themeId',
        component: ThemeComponent,
        canDeactivate: [CanDeactivateGuard],
        //data: { preload: true }  // Custom property we will use to track what route to be preloaded
      },
      {
        path: ':groupId/:themeId/:taskId',
        component: TaskComponent,
        canDeactivate: [CanDeactivateGuard],
      },

    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class GroupsRoutingModule { }
