import { Injectable } from '@angular/core';

import { CommentModel } from "../model/comment.model";
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { map, tap } from 'rxjs/operators';

/**
 * Per artifact selected on the fly get commnets - NOT CACHING comments in this version
 */
@Injectable()
export class CommentService {

    private rootPath: string = `${environment.apiUrl}/comment`;

    //All Comments for one group
    private commentSource = new BehaviorSubject<Array<CommentModel>>(new Array<CommentModel>());
    comments$ = this.commentSource.asObservable();

    constructor(private httpClient: HttpClient) { }

    // subscriptions on commnets
    getAllCommentsForGroup(groupId: number): void {
        console.log('getAllCommentsForGroup  ', groupId);
        let url: string = `${this.rootPath}/group/${groupId}`;
        this.httpClient.get<Array<CommentModel>>(url)
            .subscribe(
                data => {
                    this.commentSource.next(data);
                })
    }

    getComments() {
        return this.commentSource.getValue();
    }

    create(model: CommentModel): Observable<CommentModel> {
        let url: string = `${this.rootPath}`;
        return this.httpClient.post<CommentModel>(url, model)
            .pipe(
                tap(data => {
                    this.addToLocalCache(data);
                })
            );
    }

    update(model: CommentModel): Observable<CommentModel> {
        let url: string = `${this.rootPath}/${model.commentId}`;
        return this.httpClient.put<CommentModel>(url, model)
            .pipe(
                tap(data => {
                    this.updateLocalCache(data);
                })
            );
    }

    delete(model: CommentModel): Observable<any> {
        let cid = model.commentId;
        let url: string = `${this.rootPath}/${cid}`;
        return this.httpClient.delete(url)
            .pipe(
                tap(data => {
                    this.removeFromLocalCache(model);
                })
            );
    }

    downloadFile(commentId: number, uuidFileName: string): Observable<HttpResponse<Blob>> {
        let url = `${environment.apiUrl}/fileDownload/comment/${commentId}/${uuidFileName}`;
        return this.httpClient.get(url, { responseType: 'blob', observe: 'response' });
    }

    /**
     * ------------------------------------------------------------------------------------
     * 
     * Cache data operation
     * when we do CRUD, immedeatly after receiving OK from API we localy persist new state 
     * until receved fresh (same) data from api - so user have seemles CRUD operation in case of slow network  
     * 
     * ------------------------------------------------------------------------------------
     */
    updateLocalCache(cmnt: CommentModel) {
        console.log('updateLocalCache', cmnt)
        //Find index of specific object using findIndex method.    
        let objIndex = this.commentSource.getValue().findIndex((obj => obj.commentId === cmnt.commentId));
        //Update object 
        this.commentSource.getValue()[objIndex] = cmnt;
    }
    removeFromLocalCache(cmnt: CommentModel) {
        //Find index of specific object using findIndex method.    
        let objIndex = this.commentSource.getValue().findIndex((obj => obj.commentId === cmnt.commentId));
        //Update object 
        this.commentSource.getValue().splice(objIndex, 1);
    }
    addToLocalCache(cmnt: CommentModel) {
        console.log('comment addToLocalCache', cmnt);
        //Update object 
        this.commentSource.getValue().push(cmnt);
    }

}