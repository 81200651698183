import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { LoginResponseModel } from '../model/login-response.model';
import { AtomUserModel } from '../model/atom-user.model';

/**
 * alwais return new AtomUser - so we do not get nullPointerException's
 */
@Injectable()
export class JwtService {

    constructor() { }

    public setSession(data: LoginResponseModel) {

        const expires_in = moment().add(data.expires_in, 'second');

        sessionStorage.setItem('access_token', data.access_token);
        sessionStorage.setItem('refresh_token', data.refresh_token);
        sessionStorage.setItem("token_type", data.token_type);
        sessionStorage.setItem("expires_in", JSON.stringify(expires_in.valueOf()));
        sessionStorage.setItem("scope", data.scope);
        sessionStorage.setItem('user', JSON.stringify(data.user));
    }

    public logout() {
        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("refresh_token");
        sessionStorage.removeItem("token_type");
        sessionStorage.removeItem("expires_in");
        sessionStorage.removeItem("scope");
        sessionStorage.removeItem("user");
    }

    public isLoggedIn() {
        return moment().isBefore(this.getExpiration());
    }

    public isLoggedOut() {
        return !this.isLoggedIn();
    }

    public getExpiration() {
        const expiration = sessionStorage.getItem("expires_in");
        const expires_in = JSON.parse(expiration);
        return moment(expires_in);
    }

    public getAccessToken(): string {
        return this.isLoggedIn() ? sessionStorage.getItem("access_token") : null;
    }

    public getRefreshToken(): string {
        return this.isLoggedIn() ? sessionStorage.getItem("refresh_token") : null;
    }

    public getUser(): AtomUserModel {
        return this.isLoggedIn() ? JSON.parse(sessionStorage.getItem("user")) : new AtomUserModel();
    }
}
