/**
 * @author novica
 */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from "rxjs";
import { Message } from 'primeng/primeng'; 

// import * as socketIo from 'socket.io-client';
import { NotifyUserGroupModel } from "../model/notify-user-group.model";
import { ChatModel } from '../model/chat.model';

@Injectable()
export class NotificationService {
    public notifications: Observable<Message[]>;
    public cancelable: Observable<boolean>;
    private messages: BehaviorSubject<Message[]>;
    private cancel: BehaviorSubject<boolean>;
    private dataStore: {
        messages: Message[],
        cancelable: boolean
    };

    private socket;

    constructor() {
        this.dataStore = {
            messages: [],
            cancelable: true
        };
        this.messages = <BehaviorSubject<Message[]>>new BehaviorSubject([]);
        this.cancel = <BehaviorSubject<boolean>>new BehaviorSubject(true);
        this.notifications = this.messages.asObservable();
        this.cancelable = this.cancel.asObservable();
    }

    public socketConnect(userId: number): void {
        // this.socket = socketIo('http://socketio.subarevic.com/notify');
    }

    public onMessage(): Observable<Message> {
        return new Observable<Message>(observer => {
            // this.socket.on('message', (data: Message) => observer.next(data));
        });
    }

    socketDisconnect(): void {
        this.socket.disconnect();
    }
 
    getNotifications(): Observable<any> {
        return Observable.create(observer => {
            // this.socket.on('newuser', (data) => {
                // observer.next(<NotifyUserGroupModel>data);
            // });
        });
    }

    getChatNotifications(): Observable<Array<ChatModel>> {
        return Observable.create(observer => {
            // this.socket.on('newmsg', (data) => {
                // observer.next(<Array<ChatModel>>data);
            // });
        }); 
    }
}