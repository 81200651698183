import { Injectable } from '@angular/core';

import {
  HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse, HttpEventType, HttpResponse
} from '@angular/common/http';

import { Router } from '@angular/router';
import { tap, timeout } from 'rxjs/operators';
import { UserService } from '../services/user.service';
import { JwtService } from '../services/jwt.service';
import { UserStateModel } from '../model/user-state.model';
import { environment } from '../../environments/environment';
import { Observable, empty } from 'rxjs';
import { ThemesService } from '../services/theme.service';
import { NotifyService } from '../notify/notify.service';
import { GroupService } from '../services/group.service';

/** Pass untouched request through to the next request handler. */
@Injectable()
export class AtomInterceptor implements HttpInterceptor {


  constructor(
    private router: Router,
    private groupService: GroupService,
    private themeService: ThemesService,
    private userService: UserService,
    private jwtService: JwtService,
    private notifyService: NotifyService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const accessToken: string = this.jwtService.getAccessToken();

    console.log('*************  AtomInterceptor ', req);

    const started = Date.now();

    // this is important to set in order to handle session cookie obtained from server
    if (accessToken) {
      req = req.clone({
        setHeaders: {
          Authorization: `Bearer ${accessToken}`
        }
      });
    }

    //
    //
    //

    return next.handle(req).pipe(
      timeout(environment.serverTimeout),
      tap(
        (data) => {
          console.log('---Response: ', data);
        },
        (err => {
          console.log('AtomInterceptor', err);
          if (err instanceof HttpErrorResponse) {
            return this.handleHttpErrors(err);
          }
          if (err.name === 'TimeoutError') {
            this.notifyService.showError(err.name, 'Podaci se nisu mogli prikupiti!');
          }
        }),
        () => {
          const elapsed = Date.now() - started;
          const msg = `${req.method} "${req.urlWithParams}" in ${elapsed} ms.`;
          console.log('====>>>', msg);
        }
      )
    );

  }

  //
  //
  //

  // method to decide what to do on which response status
  private handleProgessEvents(event): void {
    console.log('data: ', event)
    // if (event.type === HttpEventType.UploadProgress) {
    //   // This is an upload progress event. Compute and show the % done:
    //   const percentDone = Math.round(100 * event.loaded / event.total);
    //   console.log(`File is ${percentDone}% uploaded.`);
    // } else if (event instanceof HttpResponse) {
    //   console.log('File is completely uploaded!');
    // }
  }

  // method to decide what to do on which response status
  private handleHttpErrors(err: HttpErrorResponse): Observable<any> {

    this.notifyService.sendToExpLessServerErr(err);

    // check if API is down
    // 20180927 removed after net::ERR_INSUFFICIENT_RESOURCES 200 (200)

    // / postoji i dole kao fukncija TODO !!!!
    // if (err.status === 0 && err.ok === false) {
    //   console.log('api is down');
    //   this.notifyService.showError(err.name, 'Server nije dostupan...');
    // }

    console.log('AtomInterceptor', err);
    console.log('AtomInterceptor', err.error);
    // when Auth error happend, backend set body json keyValue object:... value=1
    try {
      console.log('AtomInterceptor', err.error.value, err.status);
      if (err.error.value === '1') {
        return this.handleLoginErrorWrongEmail();
      }

      if (err.status === 400 && err.error.error === 'invalid_grant') {
        // wrong password will be under this statement
        this.handleLoginErrorWrongPassword();
      }

      if (err.status === 400 && err.error.error && err.error.error.indexOf('TOKEN_EXPIRE') != -1) {
        // token expire
        console.log('TOKEN EXPIRE exception from REST');
        this.handleErrorUnauthorized();
      }

      if (err.status === 400) {
        // error in api, can be bed url, or internal excepcion
        console.log('AtomInterceptor received 400, navigate to group/all');
        this.groupService.clearSelectGroup();
        this.themeService.clearAll();
        this.router.navigate(['/group/all']);
        this.notifyService.showError(err.name, 'Dogodila se greska!');

        return empty();
      }

      if (err.status === 401) {
        // redirect to the login route
        console.log('AtomInterceptor received 401');
        if (err.url.indexOf("/chk/") != -1) {
          return this.handleLoginErrorWrongEmail();
        } else {
          return this.handleErrorUnauthorized();
        }
      }

      if (err.status === 403) {
        // Forbiden - redirect to home page 
        console.log('AtomInterceptor received 403');
        this.themeService.clearAll();
        this.groupService.clearSelectGroup();
        this.router.navigate(['/group/all']);
        this.notifyService.showError('Security', 'Nemate prava za trazeni resurs');
        return empty();
      }

      /** this error should be process per component - not globaly! */
      // if (err.status === 204) {
      //   // ContentNotFound - probably file dose not exist in api
      //   console.log('AtomInterceptor received 204');
      //   this.notifyService.showError('Greska', 'Sadrzaj se nalazi na serveru');
      //   return empty();
      // }

      if ((err.status === 0) && (err.ok === false)) {
        // redirect to the login route
        console.log('AtomInterceptor received status=0 -> rest is down?');
        // clear front end state
        return this.handleErrorRestDown();
      }
      console.log('expected : unreachable---- !!!!')
      // return throw(err);
    }
    catch (error) {
      console.log(error)
      this.notifyService.sendToExpLessServerErr(error);

    }
  }


  private handleErrorUnauthorized(): Observable<any> {
    console.log('AtomInterceptor#handleErrorUnauthorized');
    // we need to clear user state, other way we will finish on cyclic redirect /dashboard /login
    let userData: UserStateModel = new UserStateModel();
    userData.user = null;
    userData.isAuthenticated = false;
    this.userService.logout();
    return empty();
  }

  private handleLoginErrorWrongEmail(): Observable<any> {
    console.log('AtomInterceptor#handleLoginErrorWrongEmail');
    // this.router.navigate(['/login'], { queryParams: { 'refresh': 1 } });
    return empty();
  }

  private handleLoginErrorWrongPassword(): void {
    console.log('AtomInterceptor#handleLoginErrorWrongPassword');
    // this.router.navigate(['/login'], { queryParams: { 'refresh': 2 } });
    this.notifyService.showError('Greska', 'Pogresna lozinka');
  }

  private handleErrorRestDown(): Observable<any> {
    console.log('AtomInterceptor#handleErrorRestDown');
    // we need to clear user state, other way we will finish on cyclic redirect /dashboard /login
    //20181002 not clearing user state
    // let userData: UserStateModel = new UserStateModel();
    // userData.user = null;
    // userData.isAuthenticated = false;
    // this.userService.logout();
    // this.router.navigate(['/login'], { queryParams: { 'refresh': 3 } });
    this.notifyService.showError('Greska', 'Server nije dostupan');
    return empty();
  }
}

