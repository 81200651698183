import { Component, OnInit, OnDestroy, Input } from '@angular/core';

import { TaskService } from "../services/task.service";

import { Subscription } from 'rxjs';
import { TaskModel } from '../model/task.model';
import { ThemeModel } from '../model/theme.model';
import { DisplayService } from '../services/display.service';

@Component({
    selector: 'task-list',
    templateUrl: './task-list.component.html',
    styleUrls: ['task-list.component.scss']
})
export class TaskListComponent implements OnInit, OnDestroy {

    // Theme for this task
    private _theme: ThemeModel;
    @Input('theme')
    set theme(theme: ThemeModel) {
        // console.log('----------------------task**********themeOnInput', theme);
        this._theme = theme;
        // refresh tasks also
        this.tasks = [];
        this.filterTasksForTheme(this.taskService.getTasks());
    }

    tasks: Array<TaskModel> = [];
    private subTasks: Subscription = new Subscription();
    private subThemeAction: Subscription = new Subscription();

    constructor(
        private taskService: TaskService,
        private displayService: DisplayService) {
    }

    ngOnInit() {

        // console.log('TaskList:  ngOnInit');
        this.subTasks = this.taskService.tasks$.subscribe(data => {
            this.filterTasksForTheme(data);
        });

        this.subThemeAction = this.displayService.themeChanged$.subscribe(data => {
            // console.log('subThemeAction ', data);
            // check if this is our theme that was changed
            if (this._theme.themeId === data.artifactId) {
                console.log('task-list -> filter for theme', data.artifactId);
                this.filterTasksForTheme(this.taskService.getTasks());
            }
        });
    }

    private filterTasksForTheme(tasksArg: TaskModel[]) {
        // get only tasks for current Theme
        this.tasks = tasksArg.filter(task => {
            return task.themeId === this._theme.themeId;
        })
            .sort((a, b) => {
                return a.orderIndex - b.orderIndex
            });
    }

    ngOnDestroy() {
        this.subTasks.unsubscribe();
        this.subThemeAction.unsubscribe();
    }
}