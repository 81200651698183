import { Message } from 'primeng/api';
import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

import * as exceptionless from '../../../node_modules/exceptionless'


@Injectable()
export class NotifyService {

    private messageSource: BehaviorSubject<Message>;
    message$: Observable<Message>;

    private message: Message;

    private client: exceptionless.ExceptionlessClient;

    constructor() {
        this.message = {
            life: 8000
        }
        this.messageSource = new BehaviorSubject<Message>(this.message);
        this.message$ = this.messageSource.asObservable();

        this.client = new exceptionless.ExceptionlessClient('a71uaqn0CCKloguHa1qmxEZ8td1Yh4imPZ9iwt7r');
        this.client.config.useDebugLogger();
    }

    showSuccess(summary: string, msg: string, closable: boolean = true): void {
        this.message.severity = 'success';
        this.message.summary = summary;
        this.message.detail = msg;
        this.message.closable = closable;
        this.messageSource.next(this.message);
    }

    showInfo(summary: string, msg: string, closable: boolean = true): void {
        this.message.severity = 'info';
        this.message.summary = summary;
        this.message.detail = msg;
        this.message.closable = closable;
        this.messageSource.next(this.message);
    }

    showError(summary: string, msg: string, closable: boolean = true): void {
        this.message.severity = 'error';
        this.message.summary = summary;
        this.message.detail = msg;
        this.message.closable = closable;
        this.messageSource.next(this.message);
    }

    showWarning(summary: string, msg: string, closable: boolean = true): void {
        this.message.severity = 'warn';
        this.message.summary = summary;
        this.message.detail = msg;
        this.message.closable = closable;
        this.messageSource.next(this.message);
    }

    showProgresMsg(summary: string, msg: string, closable: boolean = true): void {
        this.message.severity = 'info';
        this.message.summary = summary;
        this.message.detail = msg;
        this.message.closable = closable;
        this.message.key = 'keyProgresEvent';
        this.messageSource.next(this.message);
    }
    closeProgessMsg() {

    }

    sendToExpLessServer(msg: string) {
        this.client.submitLog(msg);
    }
    sendToExpLessServerErr(err: Error) {
        this.client.submitException(err);
    }
}