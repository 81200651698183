import { Component, OnInit } from '@angular/core';
import { StyleModel } from 'src/app/model/style.model';
import { DisplayService } from 'src/app/services/display.service';
import { Router, NavigationEnd } from '@angular/router';
import { GroupService } from 'src/app/services/group.service';

declare var ga: Function;

@Component({
  templateUrl: './groups.component.html',
  styleUrls: ['./groups.component.css']
})
export class GroupsComponent implements OnInit {

  public pScrollHeight: StyleModel = new StyleModel();

  private hideOnSmallDevice: boolean = false;

  constructor(
    public displayService: DisplayService,
    public router: Router,
    private groupService: GroupService
  ) {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        ga('set', 'page', event.urlAfterRedirects);
        ga('send', 'pageview');
      }
    });

    this.displayService.hideSmallDevices$.subscribe(data => {
      this.hideOnSmallDevice = data;
    });

    // TODO mozda prebaciti height u display service???
    this.pScrollHeight.width = '100%';
    // 45px -> breadcrumb
    this.pScrollHeight.height = (displayService.appHeight - 20 - 45) + 'px';

  }

  ngOnInit() {
    this.groupService.getAllGroups();
  }

}
