import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from "rxjs";

import { ThemeModel } from '../model/theme.model';
import { environment } from '../../environments/environment';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { TaskService } from './task.service';
import { map, tap } from 'rxjs/operators';
import { Router } from '@angular/router';
import { NotifyService } from '../notify/notify.service';

/**
 * 
 * ********* Use CACHING mechanizam *************
 * 
 * get all themes for one group - initialy, then use cached instace while doing in background update process of all themes
 * 
 * TODO  Izvuci FileUpload kao zasebnu direktivu!
 */
@Injectable()
export class ThemesService {

    //All Themes for one group
    private themesSource = new BehaviorSubject<Array<ThemeModel>>(new Array<ThemeModel>());
    themes$ = this.themesSource.asObservable();

    // selected Theme
    private selectedThemeSource = new BehaviorSubject<ThemeModel>(new ThemeModel());
    selectedTheme$ = this.selectedThemeSource.asObservable();

    private rootPath: string = `${environment.apiUrl}/theme`;

    constructor(
        private httpClient: HttpClient,
        private router: Router,
        private taskService: TaskService,
        private notifyService: NotifyService
    ) {
    }

    // Themes subscription, used for canceling previuose requests that are not finished yet, 
    // but user fast clicked to resend them
    // same object for all thames calls
    private subThems: Subscription;

    getAllThemesForGroup(groupId: number): void {
        console.log('getAllThemesForGroup ', groupId);
        let url: string = `${this.rootPath}/group/` + groupId;

        if (this.subThems) {
            console.log('unSubscribing...');
            this.subThems.unsubscribe();
        }

        this.subThems = this.httpClient.get<Array<ThemeModel>>(url)
            .subscribe(themes => {
                this.themesSource.next(themes);
            },
                err => {
                    console.log(err);
                });
    }

    getAllThemesForGroupSelectCurrent(groupId: number, themeId: number): void {
        console.log('getAllThemesForGroupSelectCurrent ', groupId);
        let url: string = `${this.rootPath}/group/` + groupId;

        if (this.subThems) {
            console.log('unSubscribing...');
            this.subThems.unsubscribe();
        }

        this.subThems = this.httpClient.get(url)
            .pipe(
                map(data => <Array<ThemeModel>>data)
            )
            .subscribe(themes => {
                this.themesSource.next(themes);
                // select current theme
                let filtered = themes.filter(t => {
                    // must have + sugn!! TODO chech why and correct to remove
                    return +t.themeId === +themeId
                });
                console.log('theme from url is', filtered[0]);
                if (filtered[0])
                    this.selectedThemeSource.next(filtered[0]);
                else {
                    // should never happend!
                    this.notifyService.sendToExpLessServer('No theme in response for themid=' + themeId);
                }
            },
                err => {
                    console.log(err);
                });
    }

    showThemeByIdFromApi(id: number): void {
        console.log('showThemeByIdFromApi  ', id);
        let url: string = `${this.rootPath}/${id}`;

        if (this.subThems) {
            console.log('unSubscribing...');
            this.subThems.unsubscribe();
        }

        this.subThems = this.httpClient.get(url)
            .pipe(
                map(data => <ThemeModel>data)
            )
            .subscribe(data =>
                this.setSelectedTheme(data));
    }

    showThemeByIdFromCache(id: number): void {
        console.log('showThemeByIdFromCache  ', id);
        let res = this.themesSource.getValue().filter(thm => +thm.themeId === +id);
        if (res && res.length > 0)
            this.setSelectedTheme(res[0]);
    }

    /** 
     */
    setSelectedTheme(theme: ThemeModel): void {

        console.log('setSelectedTheme ', theme);
        if (!theme) {
            // redirect to home page
            this.router.navigate(['/group/all']);
        }

        if (theme.themeId < 0) {
            // theme is DeSelect 
            // redirect to home page
            this.router.navigate(['/group/all']);
        }
        else {
            // just refresh current theme
            this.selectedThemeSource.next(theme);
        }
    }

    public getThemes() {
        return this.themesSource.getValue();
    }
    public getSelectedTheme() {
        return this.selectedThemeSource.getValue();
    }

    /**
     * -------------------------------------
     *          CRUD for Theme
     * -------------------------------------
     */

    create(theme: ThemeModel): Observable<ThemeModel> {
        let url: string = `${this.rootPath}`;
        return this.httpClient.post<ThemeModel>(url, theme)
            .pipe(
                tap(theme => {
                    this.addToLocalCache(theme);
                })
            );
    }

    update(theme: ThemeModel): Observable<ThemeModel> {
        console.log('update theme ', theme);
        let url: string = `${this.rootPath}`;
        return this.httpClient.put<ThemeModel>(url, theme)
            .pipe(
                tap(theme => {
                    this.updateLocalCache(theme);
                })
            );
    }

    delete(theme2Delete: ThemeModel): Observable<any> {
        console.log('removing from local cache', theme2Delete);
        let url: string = `${this.rootPath}/${theme2Delete.themeId}`;
        return this.httpClient.delete<ThemeModel>(url)
            .pipe(
                tap(data => {
                    this.removeFromLocalCache(theme2Delete);
                })
            )
    }

    deleteFile(theme: ThemeModel, uuidFileName: string): Observable<ThemeModel> {
        console.log('deleteFile in theme ', theme, uuidFileName);
        let url: string = `${this.rootPath}/deletefile/${theme.themeId}/${uuidFileName}`;
        return this.httpClient.delete<ThemeModel>(url).pipe(
            tap(data => {
                this.updateLocalCache(data);
            })
        );
    }

    switchThemes(groupId: number, theme1Id: number, theme2Id: number): void {
        let url: string = `${this.rootPath}/${theme1Id}/${theme2Id}`;
        console.log(url);

        // clear current theme
        this.clearAll();
        // clear current tasks
        this.taskService.clearTasks();

        this.httpClient.put(url, '')
            .subscribe(response => {
                this.getAllThemesForGroup(groupId);
                this.taskService.getAllTasksForGroup(groupId);
            });
    }

    clearAll(): void {
        console.log('clear all themes');
        // clear all themes
        this.themesSource.next([]);

        this.clearSelectedTheme();
    }

    clearSelectedTheme(): void {
        // clear selected theme 
        this.selectedThemeSource.next(new ThemeModel());
    }

    downloadFile(themeId: number, uuidFileName: string): Observable<HttpResponse<Blob>> {

        let url = `${environment.apiUrl}/fileDownload/theme/${themeId}/${uuidFileName}`;
        return this.httpClient.get(url, { responseType: 'blob', observe: 'response' });

    }

    /**
     * ------------------------------------------------------------------------------------
     * 
     * Cache data operation
     * when we do CRUD, immedeatly after receiving OK from API we localy persist new state 
     * until receved fresh (same) data from api - so user have seemles CRUD operation in case of slow network  
     * 
     * ------------------------------------------------------------------------------------
     */
    updateLocalCache(theme: ThemeModel) {
        console.log('updateLocalCache', theme)
        //Find index of specific object using findIndex method.    
        let objIndex = this.themesSource.getValue().findIndex((obj => +obj.themeId === +theme.themeId));
        console.log('updateLocalCache - index', objIndex)
        //Update object 
        this.themesSource.getValue()[objIndex] = theme;
        // reindex tasks for this theme -> now we have a hole in orderIndex array
        let counter = 10;
        this.themesSource.getValue()
            .filter(currTheme => {
                return +currTheme.groupId === +theme.groupId;
            }).forEach(element => {
                element.orderIndex = counter;
                counter = counter + 10;
            });
    }
    removeFromLocalCache(theme: ThemeModel) {
        //Find index of specific object using findIndex method.    
        let objIndex = this.themesSource.getValue().findIndex((obj => +obj.themeId === +theme.themeId));
        //Update object 
        this.themesSource.getValue().splice(objIndex, 1);
    }
    addToLocalCache(theme: ThemeModel) {
        console.log('theme addToLocalCache', theme);
        //Update object 
        this.themesSource.getValue().push(theme);
    }

}