
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ButtonModule, DropdownModule, MessagesModule } from "primeng/primeng";

import { LoginRoutingModule } from './login-routing.module';

import { LoginComponent } from './login.component'; 

@NgModule({
    imports: [
        CommonModule,
        DropdownModule,
        ButtonModule,
        MessagesModule,
        LoginRoutingModule
    ],
    declarations: [
        LoginComponent 
    ]
})
export class LoginModule {
}