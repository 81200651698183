import { Component, OnInit, OnDestroy } from '@angular/core';
import { MenuItem, ConfirmationService } from 'primeng/primeng';
import { Subscription } from 'rxjs';

import { GroupService } from "../services/group.service";
import { DisplayService } from "../services/display.service";
import { ThemesService } from '../services/theme.service';

import { GroupModel } from '../model/group.model';
import { ThemeModel } from '../model/theme.model';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService } from '../services/user.service';

declare var ga: Function;

@Component({
    selector: 'group-home',
    templateUrl: './group.component.html',
    styleUrls: ['./group.component.scss']
})
export class GroupComponent implements OnInit, OnDestroy {

    group: GroupModel = new GroupModel();
    showAddUsers: boolean = false;
    showCopyGroup: boolean = false;
    showEdit: boolean = false;

    // list of themes 
    themes: Array<ThemeModel>;

    selectedOptions: Array<MenuItem>;

    private subSelected: Subscription = new Subscription();
    private subThemes: Subscription = new Subscription();

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private groupService: GroupService,
        private confirmationService: ConfirmationService,
        private themesService: ThemesService,
        private displayService: DisplayService,
        private userService: UserService
    ) {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });

        this.route.params.subscribe(params => {

            console.log('***GroupComponent-View*******  ', params);

            let groupId = +params.groupId;
            let groupHash = params.groupHash;

            console.log('id, hash   ', groupId, groupHash);

            let selectedGroupId = +this.groupService.getSelectedGroup().groupId;
            if (isNaN(groupId)) {
                // groupId is not specified, check for groupHash
                if (groupHash) {
                    /** we have groupHash */
                    console.log('hash exist  ', groupHash);
                    this.groupService.showGroupFromApiByHash(groupHash);
                    // show all group panels
                    this.displayService.showGroupUI(true);
                }
                else {
                    // no hash - goto home
                    this.router.navigate(['group/all']);
                }
            } else {
                /** we have groupId */
                console.log('***groups# selected: ', selectedGroupId, ' param:', groupId);
                // clear selected theme - we are now in group view
                themesService.clearSelectedTheme();
                // group logic
                if (selectedGroupId !== groupId) {
                    console.log('group page->displaing for new group (not same as previuose)');
                    this.groupService.showGroupFromApi(groupId);
                    // show all group panels
                    this.displayService.showGroupUI(true);
                }
                else {
                    /**
                     * group is same - this is action when we have cached all neccesery data
                     */
                }
            }
        });
    }

    ngOnInit() {
        console.log('group ngOnInit');
        this.subSelected = this.groupService.selectedGroup$.subscribe(data => {
            console.log('subscription on selected group   ', data);
            this.group = data.group;
            this.showEdit = false;
            this.showAddUsers = false;
            this.showCopyGroup = false;
            this.formActionOptions();
        });
        this.subThemes = this.themesService.themes$.subscribe((themes: Array<ThemeModel>) => {
            console.log('subscription on selected themes   ', themes);
            this.themes = themes;
        });

    }

    ngOnDestroy() {
        this.subSelected.unsubscribe();
        this.subThemes.unsubscribe();
    }

    /**
     * Autorization
     */
    isGroupAdmin(): boolean {
        return this.groupService.isGroupAdmin(this.userService.getDataStore().user.id);
    }
    /** Is user authorise to see controls */
    showControlPerUser(): boolean {
        let result: boolean = false;
        result = this.isGroupAdmin();
        return result;
    }


    updated(show: boolean) {
        console.log('updated  ', show);
        this.showEdit = false;
        this.showAddUsers = false;
        this.showCopyGroup = false;
    }

    usersAdded(added: boolean) {
        this.showAddUsers = !added;
    }

    private formActionOptions() {
        this.selectedOptions = [
            {
                label: 'Dodaj clanove',
                command: (event: any) => {
                    this.openAddUsersForm();
                }
            },
            {
                label: 'Izmeni',
                command: (event: any) => {
                    this.openEditForm();
                }
            },
            {
                label: 'Obrisi',
                command: (event: any) => {
                    this.delete(event);
                }
            },
            {
                label: 'Kopiraj',
                command: (event: any) => {
                    this.openCopyGroupForm();
                }
            }
        ]
    }

    private delete(event: any) {
        this.confirmationService.confirm({
            message: `Da li ste sigurni da zelite da obrisete grupu: "${this.group.name}"?`,
            accept: () => {
                this.groupService.delete(this.group);
                this.showEdit = false;
                this.showAddUsers = false;
                this.showCopyGroup = false;
                // this.displayService.showGroupUI(false);
                this.router.navigate(['group/all']);
            },
            reject: () => {
                // do nothing
            }
        });
    }

    /**
     *    UI operations... call onClick
     */
    private openMenu(menu, event) {
        menu.show(event);
    }

    private openAddUsersForm() {
        this.showAddUsers = true;
        this.showEdit = false;
        this.showCopyGroup = false;
    }
    private openCopyGroupForm() {
        this.showAddUsers = false;
        this.showEdit = false;
        this.showCopyGroup = true;
    }

    private openEditForm() {
        this.showAddUsers = false;
        this.showEdit = true;
        this.showCopyGroup = false;
    }


}