import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ThemeModel } from '../model/theme.model';
import { ThemesService } from '../services/theme.service';
import { Subscription } from 'rxjs';
import { GroupService } from '../services/group.service';
import { MenuItem } from 'primeng/api';
import { ThemeViewComponent } from '../theme-view/theme-view.component';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { UserService } from '../services/user.service';
import { DisplayService } from '../services/display.service';


declare var ga: Function;

@Component({
    templateUrl: './theme.component.html',
    styleUrls: ['./theme.component.scss']
})
export class ThemeComponent implements OnInit, OnDestroy {

    @ViewChild(ThemeViewComponent) themeViewChild;

    selectedOptions: Array<MenuItem>;

    theme: ThemeModel;
    subSelectedTheme: Subscription = new Subscription();
    subDisplayThemeChanged: Subscription = new Subscription();

    showAddComment: boolean = false;
    showAddTask: boolean = false;

    constructor(
        private groupService: GroupService,
        private themesService: ThemesService,
        private router: Router,
        private route: ActivatedRoute,
        private displayService: DisplayService,
        private userService: UserService
    ) {

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                ga('set', 'page', event.urlAfterRedirects);
                ga('send', 'pageview');
            }
        });

        this.route.params.subscribe(params => { 
            let themeId = params.themeId;
            let groupId = params.groupId;
            let groupHash = params.groupHash;

            console.log('theme: id, hash   ', groupId, groupHash);

            if (isNaN(groupId)) {
                // groupId is not specified, check for groupHash
                if (groupHash || isNaN(themeId)) {
                    /** we have groupHash and themeId*/
                    console.log('hash and themeId exist  ', groupHash, themeId);
                    //if theme is not selected - user come to this page from directly clicking Link
                    if (this.themesService.getSelectedTheme().themeId < 0) {
                        // init Group and Themes from received URL
                        console.log('init group and thems from api', groupHash, themeId);
                        this.groupService.showGroupHashAndThemeFromApi(groupHash, themeId);
                        this.displayService.showGroupUI(true);
                    }
                }
                else {
                    // no hash - goto home
                    this.router.navigate(['group/all']);
                }

            } else {
                //if theme is not selected - user come to this page from directly clicking Link
                if (this.themesService.getSelectedTheme().themeId < 0) {
                    // init Group and Themes from received URL
                    console.log('init group and thems from api', groupId, themeId);
                    this.groupService.showGroupAndThemeFromApi(groupId, themeId);
                    this.displayService.showGroupUI(true);
                }
                else {
                    // we come here by page refres ususally
                    /**
                     * groupService is responsible for getting thems from api on user action on group
                     * Theme will arive from Subscription on selectedTheme
                     */
                }
            }
        });
    }

    ngOnInit() {
        console.log('*------ngOnInit ThemeComponent');

        this.subSelectedTheme = this.themesService.selectedTheme$.subscribe(data => {
            console.log('incoming new theme from subscription; ', data);
            this.theme = data;
        })

        this.subDisplayThemeChanged = this.displayService.themeChanged$.subscribe(data => {

            if (data.artifactId === this.theme.themeId) {
                this.themesService.showThemeByIdFromCache(data.artifactId);
            }
        })

        this.selectedOptions = [];
        let deleteItem: MenuItem = {
            label: 'Obrisi',
            command: () => {
                this.themeViewChild.delete();
            }
        };
        this.selectedOptions.push(deleteItem);
        let editItem: MenuItem = {
            label: 'Izmeni',
            command: () => {
                this.themeViewChild.editTheme = true;
            }
        };
        this.selectedOptions.push(editItem);
    }
    ngOnDestroy(): void {
        this.subSelectedTheme.unsubscribe();
        this.subDisplayThemeChanged.unsubscribe();
    }

    private openMenu(menu, event) {
        console.log('open menu', event);
        // must stop propagation or DOM will be all rendered and we';; have large timeouts!
        // event.stopPropagation();
        // PrimeNg's event for showing menu
        menu.show(event);
    }
    /**
     * Authorisation
     */
    isGroupAdmin(): boolean {
        try {
            return this.groupService.isGroupAdmin(this.userService.getDataStore().user.id);
        }
        catch (error) {
            console.log(error); return false;
        }
    }
    isCreator(): boolean {
        return this.theme.userId === this.userService.getDataStore().user.id;
    }
    /** Is user authorise to see controls */
    showControlPerUser(): boolean {
        let result: boolean = false;
        result = this.isGroupAdmin() || this.isCreator();
        return result;
    }

    toggleComments(event: any) {
        this.showAddComment = !this.showAddComment;
        this.showAddTask = false;
    }

    toggleTask(event: any) {
        this.showAddTask = !this.showAddTask;;
        this.showAddComment = false;
    }

}