import { NgModule, ErrorHandler } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutes } from './app.routes';

import { AccordionModule } from 'primeng/accordion';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { ChartModule } from 'primeng/chart';
import { CheckboxModule } from 'primeng/checkbox';
import { ChipsModule } from 'primeng/chips';
import { CodeHighlighterModule } from 'primeng/codehighlighter';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ColorPickerModule } from 'primeng/colorpicker';
import { ContextMenuModule } from 'primeng/contextmenu';
import { DataViewModule } from 'primeng/dataview';
import { DialogModule } from 'primeng/dialog';
import { DropdownModule } from 'primeng/dropdown';
import { EditorModule } from 'primeng/editor';
import { FieldsetModule } from 'primeng/fieldset';
import { FileUploadModule } from 'primeng/fileupload';
import { GalleriaModule } from 'primeng/galleria';
import { GrowlModule } from 'primeng/growl';
import { InplaceModule } from 'primeng/inplace';
import { InputMaskModule } from 'primeng/inputmask';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { LightboxModule } from 'primeng/lightbox';
import { ListboxModule } from 'primeng/listbox';
import { MegaMenuModule } from 'primeng/megamenu';
import { MenuModule } from 'primeng/menu';
import { MenubarModule } from 'primeng/menubar';
import { MessagesModule } from 'primeng/messages';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { OrderListModule } from 'primeng/orderlist';
import { OrganizationChartModule } from 'primeng/organizationchart';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PaginatorModule } from 'primeng/paginator';
import { PanelModule } from 'primeng/panel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { ProgressBarModule } from 'primeng/progressbar';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RatingModule } from 'primeng/rating';
import { ScheduleModule } from 'primeng/schedule';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SlideMenuModule } from 'primeng/slidemenu';
import { SliderModule } from 'primeng/slider';
import { SpinnerModule } from 'primeng/spinner';
import { SplitButtonModule } from 'primeng/splitbutton';
import { StepsModule } from 'primeng/steps';
import { TabMenuModule } from 'primeng/tabmenu';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { TerminalModule } from 'primeng/terminal';
import { TieredMenuModule } from 'primeng/tieredmenu';
import { ToastModule } from 'primeng/toast';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { ToolbarModule } from 'primeng/toolbar';
import { TooltipModule } from 'primeng/tooltip';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';

import { AppComponent } from './app.component';
import { AppMenuComponent, AppSubMenuComponent } from './app.menu.component';
import { AppSideBarComponent } from './app.sidebar.component';
import { AppSidebartabcontentComponent } from './app.sidebartabcontent.component';
import { AppTopbarComponent } from './app.topbar.component';
import { AppFooterComponent } from './app.footer.component';

import { LoginModule } from './modules/login/login.module';
import { MenuTopbarComponent } from './menu-topbar/menu-topbar.component';
import { UserService } from './services/user.service';
import { CommentService } from './services/comment.service';
import { GroupService } from './services/group.service';
import { ThemesService } from './services/theme.service';
import { TaskService } from './services/task.service';
import { ChatService } from './services/chat.service';
import { DisplayService } from './services/display.service';
import { FileService } from './services/file.service';
import { JwtService } from './services/jwt.service';
import { NotificationService } from './services/notification.service';
import { httpInterceptorProviders } from './http-interceptors/interceptors.constant';
import { NotifyComponent } from './notify/notify.component';
import { NotifyService } from './notify/notify.service';
import { MessageService } from 'primeng/api';
import { AuthServiceConfig, AuthService } from './services/auth.service';
import { GoogleLoginProvider } from './social-providers';
import { CustomErrorHandler } from './app-shared/error-hendler/custom-error-handler';
import { PublicGroupModule } from './modules/public-group/public-group.module';
import { GroupsModule } from './modules/groups/groups.module';
import { PlutaRouterService } from './services/pluta-router.service';

import { LayoutModule } from '@angular/cdk/layout';

// Configs 
export function getAuthServiceConfigs() {
    let config = new AuthServiceConfig(
        [
            // {
            //     id: FacebookLoginProvider.PROVIDER_ID,
            //     provider: new FacebookLoginProvider("Your-Facebook-app-id")
            // },
            {
                id: GoogleLoginProvider.PROVIDER_ID,
                provider: new GoogleLoginProvider("56700830968-jf712bb56i9kg6ggqbnikrp7a9030opj.apps.googleusercontent.com")
            },
            // {
            //     id: LinkedinLoginProvider.PROVIDER_ID,
            //     provider: new LinkedinLoginProvider("109sercontent.com")
            // },
        ]
    );
    return config;
}
@NgModule({
    imports: [
        BrowserModule,
        LayoutModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AccordionModule,
        AutoCompleteModule,
        BreadcrumbModule,
        ButtonModule,
        CalendarModule,
        CardModule,
        CarouselModule,
        ChartModule,
        CheckboxModule,
        ChipsModule,
        CodeHighlighterModule,
        ConfirmDialogModule,
        ColorPickerModule,
        ContextMenuModule,
        DataViewModule,
        DialogModule,
        DropdownModule,
        EditorModule,
        FieldsetModule,
        FileUploadModule,
        GalleriaModule,
        GrowlModule,
        InplaceModule,
        InputMaskModule,
        InputSwitchModule,
        InputTextModule,
        InputTextareaModule,
        LightboxModule,
        ListboxModule,
        MegaMenuModule,
        MenuModule,
        MenubarModule,
        MessageModule,
        MessagesModule,
        MultiSelectModule,
        OrderListModule,
        OrganizationChartModule,
        OverlayPanelModule,
        PaginatorModule,
        PanelModule,
        PanelMenuModule,
        PasswordModule,
        PickListModule,
        ProgressBarModule,
        RadioButtonModule,
        RatingModule,
        ScheduleModule,
        ScrollPanelModule,
        SelectButtonModule,
        SlideMenuModule,
        SliderModule,
        SpinnerModule,
        SplitButtonModule,
        StepsModule,
        TableModule,
        TabMenuModule,
        TabViewModule,
        TerminalModule,
        TieredMenuModule,
        ToastModule,
        ToggleButtonModule,
        ToolbarModule,
        TooltipModule,
        TreeModule,
        TreeTableModule,
        PublicGroupModule,
        GroupsModule,
        LoginModule

    ],
    declarations: [
        AppComponent,
        AppMenuComponent,
        AppSubMenuComponent,
        AppSideBarComponent,
        AppSidebartabcontentComponent,
        AppTopbarComponent,
        AppFooterComponent,
        MenuTopbarComponent,
        NotifyComponent
    ],
    providers: [
        UserService,
        CommentService,
        GroupService,
        ThemesService,
        TaskService,
        ChatService,
        DisplayService,
        FileService,
        JwtService,
        NotificationService,
        httpInterceptorProviders,
        NotifyService,
        MessageService,
        PlutaRouterService,
        AuthService,
        {
            provide: AuthServiceConfig,
            useFactory: getAuthServiceConfigs
        },
        {
            provide: ErrorHandler,
            useClass: CustomErrorHandler
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {

}
