import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MenuItem } from 'primeng/primeng';

import { UserService } from '../services/user.service';
import { ChatService } from '../services/chat.service';
import { NotificationService } from '../services/notification.service';

import { ChatModel } from '../model/chat.model';
import { ChatInvokeModel } from '../model/chat-invoke.model';


@Component({
    selector: 'vmk-chat',
    templateUrl: './chat.component.html',
    styleUrls: [ './chat.component.scss' ]
})
export class ChatComponent implements OnInit, OnDestroy {

    @Input() invokeState: ChatInvokeModel;
    @Input() chatId: number;

    chatForm: FormGroup;
    messages: Array<ChatModel>;

    constructor(private chatService: ChatService,
                private notifyService: NotificationService,
                private fb: FormBuilder,
                private userService: UserService) {
                    this.messages = [];
                }

    ngOnInit() {
        this.initForm();
        this.chatService.getMessages().subscribe((messages: Array<ChatModel>) => {
            this.messages = messages;
        });
        this.notifyService.getChatNotifications().subscribe(
            (data: Array<ChatModel>) => {
                this.messages = data;
            }
        );
        this.chatService.chatIsOpened(true);
    }

    ngOnDestroy() {
        this.chatService.chatIsOpened(false);
    }

    send() {
        let model: ChatModel = this.getFormValue();
        this.chatService.sendMessage(model);
        this.chatForm.controls['message'].reset();
    }

    isChatInvoker(message: ChatModel): boolean {
        return this.userService.getDataStore().user.id === message.userId;
    }

    private initForm() {
        this.chatForm = this.fb.group({
            userId: [ this.userService.getDataStore().user.id ],
            userId2: [ this.invokeState.userId2 ],
            chatId: [ this.invokeState.chatId ],
            message: [ '', Validators.required ]
        });
    }

    private getFormValue(): ChatModel {
        let model: ChatModel = this.chatForm.value;
        return model;
    }
}