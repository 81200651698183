import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { UserService } from './services/user.service';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html',
})
export class AppFooterComponent implements OnInit {

    private isAuthenticated: boolean = false;
    private subscription: Subscription = new Subscription();

    constructor(
        private userService: UserService) {

    }

    ngOnInit() {
        this.subscription = this.userService.userData$.subscribe(data => {
            console.log('footer onInit: ', data);
            this.isAuthenticated = data.isAuthenticated;
        });
    }
}
