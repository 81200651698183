import { Injectable } from '@angular/core';

import { map } from 'rxjs/operators';

import { CommentModel } from "../model/comment.model";
import { environment } from '../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';

@Injectable()
export class FileService {

    private rootPath: string = `${environment.apiUrl}/file`;

    constructor(private httpClient: HttpClient) { }

    getAll(id: number, type: number): Observable<Array<CommentModel>> {
        let url: string = `${this.rootPath}`;
        let params: URLSearchParams = new URLSearchParams();
        params.set('artifactType', type.toString());
        params.set('artifactId', id.toString());
        return this.httpClient.get(url, {
            params:
            {
                'artifactType': type.toString(),
                'artifactId': id.toString()
            }
        })
            .pipe(
                map(data => (<Array<CommentModel>>data).reverse())
            );
    }

    getById(id: number): Observable<CommentModel> {
        let url: string = `${this.rootPath}/${id}`;
        return this.httpClient.get(url)
            .pipe(
                map(data => <CommentModel>data)
            );
    }

    create(model: CommentModel): Observable<CommentModel> {
        console.log(model);
        let url: string = `${this.rootPath}`;
        return this.httpClient.post(url, model)
            .pipe(
                map(data => <CommentModel>data)
            );
    }

    update(model: CommentModel): Observable<CommentModel> {
        let url: string = `${this.rootPath}/${model.commentId}`;
        return this.httpClient.put(url, model)
            .pipe(
                map(data => <CommentModel>data)
            );
    }

    delete(model: CommentModel): Observable<any> {
        let url: string = `${this.rootPath}/${model.commentId}`;
        return this.httpClient.delete(url);
    }

}