import { LoginProviderClass, SocialUser } from "../social-entities";
import { BaseLoginProvider } from "../social-entities/base-login-provider";



declare let gapi: any;

export class GoogleLoginProvider extends BaseLoginProvider {

  public static readonly PROVIDER_ID = 'google';
  public loginProviderObj: LoginProviderClass = new LoginProviderClass();
  private auth2: any;

  constructor(private clientId: string) {
    super();
    this.loginProviderObj.id = clientId;
    this.loginProviderObj.name = 'google';
    this.loginProviderObj.url = 'https://apis.google.com/js/platform.js';
  }

  initialize(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      this.loadScript(this.loginProviderObj, () => {
        gapi.load('auth2', () => {
          this.auth2 = gapi.auth2.init({
            client_id: this.clientId,
            scope: 'email',
            prompt: 'select_account'
          });

          this.auth2.then(() => {
            if (this.auth2.isSignedIn.get()) {
              resolve(this.drawUser());
            }
          });
        });
      });
    });
  }

  drawUser(): SocialUser {
    let user: SocialUser = new SocialUser();
    let profile = this.auth2.currentUser.get().getBasicProfile();
    let authResponseObj = this.auth2.currentUser.get().getAuthResponse(true);
    user.id = profile.getId();
    user.name = profile.getName();
    user.email = profile.getEmail();
    user.image = profile.getImageUrl();
    user.token = authResponseObj.access_token;
    user.idToken = authResponseObj.id_token;
    return user;
  }

  signIn(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      //TODO
      /**
       * Uncaught (in promise): TypeError: Cannot read property 'signIn' of undefined TypeError: Cannot read property 'signIn' of undefined at http://pluta.io/main.js:6064:39 at new ZoneAwarePromise (http://pluta.io/polyfills.js:7685:29) at GoogleLoginProvider.push../src/app/social-providers/google-login-provider.ts.GoogleLoginProvider.signIn (http://pluta.io/main.js:6063:16) at http://pluta.io/main.js:4306:32 at new ZoneAwarePromise (http://pluta.io/polyfills.js:7685:29) at AuthService.push../src/app/services/auth.service.ts.AuthService.signIn (http://pluta.io/main.js:4303:16) at LoginComponent.push../src/app/modules/login/login.component.ts.LoginComponent.socialSignIn (http://pluta.io/main.js:3878:32) at Object.eval [as handleEvent] (ng:///LoginModule/LoginComponent.ngfactory.js:28:31) at handleEvent (http://pluta.io/vendor.js:49064:41) at callWithDebugContext (http://pluta.io/vendor.js:50157:25) at Object.debugHandleEvent [as handleEvent] (http://pluta.io/vendor.js:49860:12) at dispatchEvent (http://pluta.io/vendor.js:46523:25) at http://pluta.io/vendor.js:46967:38 at HTMLButtonElement.<anonymous> (http://pluta.io/vendor.js:65265:36) at ZoneDelegate.push../…
       */
      let promise = this.auth2.signIn();
      promise.then(() => {
        resolve(this.drawUser());
      });
    });
  }

  signOut(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.auth2.signOut().then((err: any) => {
        if (err) {
          reject(err);
        } else {
          resolve();
        }
      });
    });
  }

}
