import { Component, OnInit, Input, OnDestroy } from '@angular/core';

import { TaskService } from '../services/task.service';

import { ThemeModel } from '../model/theme.model';
import { Subscription } from 'rxjs';
import { TaskModel } from '../model/task.model';
import { Router, ActivatedRoute } from '@angular/router';
import { GroupService } from '../services/group.service';
import { DisplayService } from '../services/display.service';
import { group } from '@angular/animations';

@Component({
    selector: 'task-name-list',
    templateUrl: './task-name-list.component.html'
})
export class TaskNameListForThemeComponent implements OnInit, OnDestroy {

    @Input() theme: ThemeModel;

    tasks: Array<TaskModel> = [];
    private subTasks: Subscription = new Subscription();
    private subThemeAction: Subscription = new Subscription();

    // drag and drop 
    private draggedTask: TaskModel;
    private dragStarted: boolean = false;
    subGroupSelected: Subscription = new Subscription();
    private noData: boolean = true;

    constructor(
        private groupService: GroupService,
        private displayService: DisplayService,
        private route: ActivatedRoute,
        private taskService: TaskService,
        private router: Router
    ) {
        this.route.params.subscribe(
        );
    }

    ngOnInit() {

        // get task if thay arrive before Theme (very rear...)
        this.filterTasksForTheme(this.taskService.getTasks());

        // or tasks arrive after theme (in 99% this is the case)
        this.subTasks = this.taskService.tasks$.subscribe(data => {
            // console.log('data:',data);
            this.filterTasksForTheme(data);
        });

        this.subThemeAction = this.displayService.themeChanged$.subscribe(data => {
            // check if this is our theme that was changed
            if (this.theme.themeId === data.artifactId) {
                console.log('task-name-list -> filter for theme', data.artifactId);
                this.filterTasksForTheme(this.taskService.getTasks());
            }
        });
    }

    ngOnDestroy() {
        this.subTasks.unsubscribe();
        this.subThemeAction.unsubscribe();
    }

    private filterTasksForTheme(tasksArg: TaskModel[]) {
        // get only tasks for current Theme
        this.tasks = tasksArg.filter(task => {
            return +task.themeId === +this.theme.themeId;
        }).sort((a, b) => {
            return a.orderIndex - b.orderIndex;
        });
        if (this.tasks.length > 0)
            this.noData = false;
        else
            this.noData = true;
    }

    selectTask(task: TaskModel) {
        this.taskService.setSelectedTask(task);

        let path = this.router.url;
        console.log(path, path.indexOf('/public/'));
        if (path.indexOf('/public/') === 0) {
            // public group url 
            console.log('public url');
            this.router.navigate([
                this.groupService.getSelectedGroup().groupId,
                this.theme.themeId,
                task.taskId
            ],
                { relativeTo: this.route }
            );
            console.log(this.router.url);
        }
        else {
            // user is logged in, regulat URL path, navigate to Theme page
            console.log('private url');
            this.router.navigate([
                this.groupService.getSelectedGroup().groupId,
                this.theme.themeId,
                task.taskId
            ],
                { relativeTo: this.route }
            );
        }

        // navigate to Task page
        this.router.navigate([
            this.groupService.getSelectedGroup().groupId,
            this.theme.themeId,
            task.taskId
        ],
            { relativeTo: this.route }
        );

    }


    /**
     * 
     * Drag&Drop
     * 
     * 
     */
    dragStart(event, task: TaskModel) {
        console.log('drag start', task);
        this.dragStarted = true;
        this.draggedTask = task;
    }

    drop(event, task: TaskModel) {
        console.log('switching...   ', this.draggedTask, task);
        if (this.draggedTask) {
            if (this.draggedTask.taskId !== task.taskId) {

                // the are different, check now if they are next to each othe
                if (this.checkOrder(task))
                    return;

                this.taskService.switchTasks(this.theme.groupId, this.theme.themeId, this.draggedTask.taskId, task.taskId);
            }
            else {
                console.log('same task - nothing to switch');
            }
        }

        this.draggedTask.dropEffect = false;
        this.draggedTask.dropEffectNotAllowed = false;
        task.dropEffect = false;
        task.dropEffectNotAllowed = false;
    }

    /**
     * 
     * @param dropped check if dragable theme is Uper then droppable theme for ONE step.
     * In this case we do not show UI for dropping area - since nothing will happend - we only move one step up bu definition
     */
    private checkOrder(dropped: TaskModel) {
        let dragIndex = this.tasks.indexOf(this.draggedTask);
        let dropIndex = this.tasks.indexOf(dropped);
        console.log(dragIndex, dropIndex);
        if (dragIndex - dropIndex === -1) {
            // dragged is one step upper then dropped. Do nothing
            console.log('Dragged is one step in front of dropped object. Nothing is changed...');
            dropped.dropEffectNotAllowed = false;
            dropped.dropEffect = false;
            return true;
        }
        return false;
    }
    dragEnd(event) {
        this.draggedTask = null;
        this.dragStarted = false;
    }

    dragEnter(event, task: TaskModel) {
        console.log(this.draggedTask, task);
        if (this.draggedTask && task) {
            if (this.draggedTask.taskId != task.taskId) {
                console.log('different task check order');

                // the are different, check now if they are next to each othe
                if (this.checkOrder(task)) {
                    task.dropEffectNotAllowed = true;
                    return;
                }

                task.dropEffect = true;
                task.dropEffectNotAllowed = false;
            }
            else {
                console.log('same Task - not enable for switching');
                task.dropEffectNotAllowed = true;
            }
        } else console.log('tasks are not define');
        console.log(task.dropEffect, task.dropEffectNotAllowed);
    }

    dragLeave(event, task: TaskModel) {
        task.dropEffect = false;
        task.dropEffectNotAllowed = false;
    }
}