import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { GroupService } from "../services/group.service";
import { GroupModel } from '../model/group.model';
import { NotifyService } from '../notify/notify.service';

@Component({
    selector: 'group-update',
    templateUrl: './group-update.component.html'
})
export class GroupUpdateComponent implements OnInit {

    @Input() group: GroupModel;
    @Output() updated: any = new EventEmitter<boolean>();

    updateForm: FormGroup;

    /** used in html */
    private urlPrefix: string = 'http://pluta.io/public/';
    isCopied: boolean = false;

    constructor(
        private groupService: GroupService,
        private notifyService: NotifyService,
        private fb: FormBuilder
    ) {
    }

    ngOnInit() {
        this.initUpdateForm();
    }

    update() {
        let updateModel: GroupModel = this.getFormValueUpdate();
        // copy to curently selected group
        this.group.name = updateModel.name;
        this.group.description = updateModel.description;
        this.group.publicStatus = updateModel.publicStatus;
        this.group.publicUrl = updateModel.publicUrl;
        this.groupService.update(this.group)
            .subscribe((data: GroupModel) => {
                this.groupService.showGroup(this.group);
                this.updated.emit(true);
            });
    }

    cancelUpdate() {
        this.updated.emit(true);
    }

    private initUpdateForm() {
        this.updateForm = this.fb.group({
            groupId: [this.group.groupId, Validators.required],
            name: [this.group.name, Validators.required],
            description: [this.group.description],
            publicStatus: [false],
            publicUrl: [this.group.publicUrl]
        });

    }
    copy2clipborad() {
        this.notifyService.showInfo('Copy', 'Uspesno iskopiran javni link u clipBoard');
    }

    private getFormValueUpdate(): GroupModel {
        let model: GroupModel = <GroupModel>{
            groupId: this.updateForm.controls['groupId'].value,
            name: this.updateForm.controls['name'].value,
            description: this.updateForm.controls['description'].value,
            publicStatus: this.updateForm.controls['publicStatus'].value
        };
        return model;
    }
}